import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Stack } from '@mui/material';
import './InsightsMultipleDayChartStyles.css';
import { useSelector } from 'react-redux';
import moment from 'moment';
import MainCard from 'ui-component/cards/MainCard';
import { dateFormat, getDate } from 'utils/share';

const InsightsParticipationChartForMultipleDays = ({ standupDetail }) => {
    const [participantData, setParticipantData] = useState({
        totalParticipantsValue: 0,
        totalResponseValue: 0,
        participantPercentageValue: 0
    });
    const { multipleDayInsights, insightsLoading } = useSelector((state) => state.insights);
    useEffect(() => {
        let totalParticipants =
            multipleDayInsights?.totalParticipants > 0 ? multipleDayInsights?.totalParticipants : standupDetail?.Participants?.length;

        let totalResponse = multipleDayInsights?.totalResponse;

        let participantPercentage = (parseFloat(totalResponse) * 100) / totalParticipants;

        setParticipantData({
            totalParticipantsValue: totalParticipants,
            totalResponseValue: totalResponse,
            participantPercentageValue: participantPercentage
        });
    }, [multipleDayInsights, standupDetail]);

    const test =
        multipleDayInsights?.chartData &&
        JSON.parse(JSON.stringify(multipleDayInsights?.chartData))?.map((a) => ({ ...a, date: moment(a?.date).format('YYYY-MM-DD') }));

    const result =
        test &&
        Object.values(
            test?.reduce((acc, item) => {
                acc[item.date] = acc[item.date] ? { ...item, reportedUser: item.reportedUser + acc[item.date].reportedUser } : item;
                return acc;
            }, {})
        ).sort((a, b) => {
            return moment(a?.date).diff(b?.date);
        });

    let chartDatas = result?.map((item) => ({
        ...item,
        x: new moment(item?.date).format('MMM, DD'),
        y: Math.round((item?.reportedUser * 100) / item.totalUser)
    }));

    var options = {
        series: [
            {
                name: 'Insights',
                data: chartDatas
            }
        ],
        chart: {
            type: 'area',

            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        colors: ['#d4d4d4'],
        title: {
            text: 'Participation',
            align: 'left'
        },

        xaxis: {
            showLabels: 2
        },
        yaxis: {
            opposite: true
        },
        legend: {
            horizontalAlign: 'left'
        },
        tooltip: {
            marker: {
                show: true
            },

            fixed: {
                enabled: false,
                position: 'topRight',
                offsetX: 0,
                offsetY: 0
            },
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                return `<div class='chartWrapper' >
                <div class='chartHeader' >  ${w.config.series[0].data[dataPointIndex].x}</div>

                  <div  class='chartPercentage'>

                  ${series[seriesIndex][dataPointIndex]}%

                  </div>
                   <div  class='chartReportCount'>
                    Reported:
                             <div  class='chartReportValue'> ${w.config.series[0].data[dataPointIndex].reportedUser}
                             </div>
                    people out of
                           <div  class='chartReportValue'>
                                 ${w.config.series[0].data[dataPointIndex].totalUser}
                          </div>
                 </div>

                    </div>`;
            }
        }
    };

    return (
        <MainCard
            border={false}
            sx={{
                width: '100%',
                p: 3,
                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)',
                ':hover': {
                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)'
                }
            }}
        >
            <Chart options={options} series={options.series} type="area" height="250px" />
        </MainCard>
    );
};

export default InsightsParticipationChartForMultipleDays;
