import React, { useState, useEffect } from 'react';
import { Stack, Box, Grid, Typography, Skeleton, useTheme } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import InsightsPartcipationChartAnimation from './InsightsPartcipationChartAnimation';
import InsightsParticipantsSkeleton from './InsightsParticipantsSkeleton';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from 'react-redux';
import moment from 'moment';

const InsightsParticipation = ({ standupDetail, previousDate }) => {
    const theme = useTheme();
    const [participantData, setParticipantData] = useState({
        totalParticipantsValue: 0,
        totalResponseValue: 0,
        participantPercentageValue: 0,
        totalPreviousResponseValue: 0,
        previousPercentageValue: 0,
        sinceTotalPercentageValue: 0
    });
    const { singleDayInsights, insightsLoading, previousDayInsights } = useSelector((state) => state.insights);
    useEffect(() => {
        let totalParticipants =
            singleDayInsights?.totalParticipants > 0 ? singleDayInsights?.totalParticipants : standupDetail?.Participants?.length;

        let totalResponse =
            singleDayInsights?.totalResponse > 0
                ? singleDayInsights?.totalResponse
                : singleDayInsights?.Questions?.length && Object.keys(singleDayInsights?.Questions[0]?.userCount)?.length;

        let participantPercentage = (parseFloat(totalResponse) * 100) / totalParticipants;

        let totalPreviousResponse =
            previousDayInsights?.totalResponse > 0
                ? previousDayInsights?.totalResponse
                : previousDayInsights?.Questions?.length && Object.keys(previousDayInsights?.Questions[0]?.userCount)?.length;

        let previousPercentage = (parseFloat(totalPreviousResponse) * 100) / totalParticipants;

        let sinceTotalPercentage = parseFloat(participantPercentage) - parseFloat(previousPercentage);
        setParticipantData({
            totalParticipantsValue: totalParticipants,
            totalResponseValue: totalResponse,
            participantPercentageValue: participantPercentage,
            totalPreviousResponseValue: totalPreviousResponse,
            previousPercentageValue: previousPercentage,
            sinceTotalPercentageValue: sinceTotalPercentage
        });
    }, [singleDayInsights, previousDayInsights, standupDetail]);

    return (
        <Box>
            {!insightsLoading ? (
                <MainCard
                    border={false}
                    sx={{
                        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)',
                        ':hover': {
                            boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)'
                        }
                    }}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="h2"
                                sx={{
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: '16px'
                                    }
                                }}
                            >
                                Participation
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                            <Stack sx={{ width: '50%' }}>
                                <InsightsPartcipationChartAnimation
                                    valueStart={0}
                                    valueEnd={participantData.participantPercentageValue || 0}
                                    duration={1.4}
                                    repeat={true}
                                    pathTransitionDuration={2}
                                >
                                    {(value) => {
                                        const roundedValue = Math.round(value);
                                        return <CircularProgressbar value={value} text={`${roundedValue}%`} />;
                                    }}
                                </InsightsPartcipationChartAnimation>
                            </Stack>
                        </Grid>
                        <Grid item display="flex" justifyContent="center" flexDirection="column" alignItems="center" xs={12} sx={{ mt: 3 }}>
                            <Grid item display="flex" justifyContent="center" alignItems="center">
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontWeight: 100,
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '13px'
                                        }
                                    }}
                                >
                                    Reported:
                                </Typography>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontWeight: 900,
                                        ml: 1,
                                        mr: 1,
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '13px'
                                        }
                                    }}
                                >
                                    {participantData.totalResponseValue}
                                </Typography>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontWeight: 100,
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '13px'
                                        }
                                    }}
                                >
                                    people out of
                                </Typography>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontWeight: 900,
                                        ml: 1,
                                        mr: 1,
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '13px'
                                        }
                                    }}
                                >
                                    {participantData.totalParticipantsValue}
                                </Typography>
                            </Grid>
                            <Grid item display="flex" justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
                                <Typography
                                    variant="h4"
                                    textAlign="center"
                                    sx={{
                                        fontWeight: 100,
                                        color: `${participantData.sinceTotalPercentageValue >= 0 ? 'green' : 'red'}`,
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '13px'
                                        }
                                    }}
                                >
                                    {participantData.sinceTotalPercentageValue >= 0 ? '+' : ''}
                                    {isNaN(participantData.sinceTotalPercentageValue.toFixed(2))
                                        ? (0.0).toFixed(2)
                                        : participantData.sinceTotalPercentageValue.toFixed(2)}
                                    %
                                </Typography>
                                <Typography
                                    variant="h4"
                                    textAlign="center"
                                    sx={{
                                        ml: 1,
                                        mr: 1,
                                        fontWeight: 100,
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '13px'
                                        }
                                    }}
                                >
                                    since
                                </Typography>
                                <Typography
                                    variant="h4"
                                    textAlign="center"
                                    sx={{
                                        fontWeight: 100,
                                        [theme.breakpoints.up('md')]: {
                                            fontSize: '13px'
                                        }
                                    }}
                                >
                                    {moment(previousDate).format('dddd, MMM Do')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainCard>
            ) : (
                <InsightsParticipantsSkeleton />
            )}
        </Box>
    );
};

export default InsightsParticipation;
