import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import { Divider } from '@mui/material';

const Modal = (props) => {
    const theme = useTheme();
    return (
        <div>
            <Dialog open={props.open} onClose={props.toggle} {...props}>
                <DialogTitle variant="h4" id="alert-dialog-title" sx={{ color: theme.palette.grey[900], fontWeight: 600 }}>
                    {props.title}
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ margin: '10px 0px' }}>
                    <DialogContentText sx={{ fontSize: '1rem', color: theme.palette.grey[500], fontWeight: 500 }}>
                        {props.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disableElevation
                        onClick={props.toggle}
                        variant={'contained'}
                        size="small"
                        sx={{
                            backgroundColor: theme.palette.grey[400],
                            '&:hover': {
                                backgroundColor: theme.palette.grey[500]
                            }
                        }}
                    >
                        {props.LeftButton}
                    </Button>
                    <Button
                        onClick={props.actionClick}
                        variant={'contained'}
                        disableElevation
                        size="small"
                        color={props.actionButtonColor || 'primary'}
                    >
                        {props.RightButton}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Modal;
