import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Stack } from '@mui/material';
import './InsightsMultipleDayChartStyles.css';
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import MainCard from 'ui-component/cards/MainCard';

const InsightsMultipleDayChartSkeleton = () => {
    var options = {
        series: [
            {
                name: 'Insights',

                data: [
                    {
                        x: '2018-09-05',
                        report: 'success1',
                        y: 140
                    },
                    {
                        x: '2018-09-09',
                        y: 340,
                        report: 'success2'
                    },
                    {
                        x: '2018-09-12',
                        y: 120,
                        report: 'success3'
                    },
                    {
                        x: '2018-09-15',
                        y: 270,
                        report: 'success3'
                    },
                    {
                        x: '2018-09-20',
                        report: 'success1',
                        y: 100
                    },
                    {
                        x: '2018-09-22',
                        y: 300,
                        report: 'success2'
                    },
                    {
                        x: '2018-09-25',
                        y: 60,
                        report: 'success3'
                    }
                ]
            }
        ],
        chart: {
            type: 'area',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        colors: ['#d4d4d4'],
        title: {
            text: '',
            align: 'left'
        },

        xaxis: {
            labels: {
                show: false
            },
            tooltip: {
                enabled: false
            }
        },
        yaxis: {
            opposite: true
        },
        legend: {
            horizontalAlign: 'left'
        }
    };

    return (
        <MainCard
            border={false}
            headerSX={{ p: 2 }}
            sx={{
                width: '100%',
                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)',
                ':hover': {
                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)'
                }
            }}
            title={<Skeleton variant="text" width="10%" />}
        >
            <Chart options={options} series={options.series} type="area" height="250px" />
        </MainCard>
    );
};

export default InsightsMultipleDayChartSkeleton;
