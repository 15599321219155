import { Typography } from '@mui/material';
import ReactEmoji from 'react-emoji';
import Markdown from 'markdown-to-jsx';
import moment from 'moment-timezone';
import { daysList } from './common';

export const reduceColorOpacity = (color) => {
    let alpha = [0.2, 0.4, 0.6, 0.8, 1];
    return alpha.map((opacity, i) => {
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return { [i]: color + _opacity.toString(16).toUpperCase() };
    });
};
export const getUser = () => {
    let user = JSON.parse(JSON.parse(localStorage.getItem('persist:root')).login);
    return user;
};
export const getTimeZoneList = () => {
    let getTimeZoneList = moment.tz.names().map((t) => {
        return { title: `(GMT${moment.tz(t).format('Z')}) ${t}`, value: t };
    });
    return getTimeZoneList;
};

export const getUserTimeZone = () => {
    var localTimeZone = moment.tz.guess();
    return localTimeZone;
};

export const livePreviewMessageHandler = (message, standup, userName) => {
    let mapping = {};
    let replaceArray = [`hello`, 'name', 'standup', 'bye'];
    let replaceWith = [`Hello ${userName}!`, userName, standup, 'Good job! 🎉'];
    replaceArray.forEach((e, i) => (mapping[`{${e}}`] = replaceWith[i]));
    return message.replace(/\{\w+\}/gi, (n) => mapping[n]);
};

export const getDaysList = (dayList) => {
    let newArray = [];
    let WeekDay = [
        { title: 'Monday', value: 1 },
        { title: 'Tuesday', value: 2 },
        { title: 'Wednesday', value: 3 },
        { title: 'Thursday', value: 4 },
        { title: 'Friday', value: 5 },
        { title: 'Saturday', value: 6 },
        { title: 'Saturday', value: 7 }
    ];
    dayList.filter((array22) => {
        let found = WeekDay.find((array11) => array11.value === array22);
        if (found) newArray.push(found.title);
    });
    return newArray;
};
export const getCapitalize = (string) => {
    if (typeof string !== 'string') {
        return '';
    } else {
        return string
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
            .join(' ');
    }
};
export const getScheduleType = (key) => {
    return daysList.filter((data) => data.key === key)[0]?.title;
};
export const formatForMultipleWeek = (dayList) => {
    return dayList.map((schedule, day) => {
        if (schedule.toString() === [1, 2, 3, 4, 5].toString()) {
            return `${day === 0 ? 'First Week' : day === 1 ? 'Second Week' : day === 3 ? 'Third Week' : ''}  start from Monday to Friday`;
        } else {
            let newArray = getDaysList(schedule);
            const items = newArray.slice(0, newArray.length - 1);
            let days = items.join(' , ');
            return (
                `${day === 0 ? 'First' : day === 1 ? 'Second' : day === 2 ? 'Third' : day === 3 ? 'Fourth' : 'Weekly'} Week start from   ` +
                '' +
                days +
                ' ' +
                'and' +
                ' ' +
                newArray[newArray.length - 1]
            );
        }
    });
};
export const isLastPermission = (members, permission) => {
    if (members.filter((e) => e.permissions[permission]).length === 1) {
        return true;
    } else return false;
};
export const dateFormat = (date) => {
    const monthNames = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const currentDate = new Date(date);
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    let day = currentDate.getDate();
    return monthNames[month] + ' ' + day + ', ' + year;
};

export const getDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
};
export const separateMeridiem = (value) => value?.slice(value?.length - 2);

export const separateTime = (value) => value?.slice(0, value?.length - 2);
export const randomColorPicker = (items) => {
    return items[Math.floor(Math.random() * items.length)];
};
export const convertRange = (value, r1, r2) => {
    return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
};

export const stringFormatConverter = (string) => {
    const boldRegex = /\*/g;
    const linkRegex = /<(.*?)>/gi;
    let replacedText = string.replace(boldRegex, '**');

    let replacedAnswer = replacedText.replace(linkRegex, (match, i) => match.slice(1).split('|')[0]);

    let bulletIndex = string.indexOf('•');
    let boldStart = string.indexOf('*');
    let boldEnd = string.lastIndexOf('*');
    let startLink = replacedText.indexOf('<');
    let endLink = replacedText.indexOf('>');
    let isBulletStyle = bulletIndex !== -1;
    let isBoldStyle = boldStart !== -1 && boldEnd !== -1;
    let isLinkStyle = startLink !== -1 && endLink !== -1;

    let isEmoji = !isBulletStyle && !isBoldStyle && !isLinkStyle;

    return { isEmoji, replacedAnswer };
};
export const seperateResponseAnswerString = (string) => {
    if (typeof string !== 'string') {
        return '';
    } else {
        let answers = string.split('\n');
        return answers.map((answer, i) => {
            const { isEmoji, replacedAnswer } = stringFormatConverter(answer);

            return isEmoji ? <Typography>{ReactEmoji.emojify(answer)}</Typography> : <Markdown>{replacedAnswer}</Markdown>;
        });
    }
};
export const convertTimeFormatTo24Hour = (timeStr) => {
    const time = moment(`${timeStr}`, ['h:mm A']).format('HH:mm');
    return `${time}`;
};

export const convertTimeFormatTo12Hour = (timeStr) => {
    const time = moment(`${timeStr}`, ['HH.mm']).format('hh:mm A');
    return `${time}`;
};

export const getScheduleDays = (type, scheduleType, date, selectedTime, setDate, selectedDays) => {
    let scheduleData = {};
    if (type === 'date') {
        scheduleData = {
            [scheduleType]: moment(`${date} ${convertTimeFormatTo24Hour(selectedTime)}`, 'YYYY-MM-DD HH:mm:ss.SSS  Z').format(
                'YYYY-MM-DD HH:mm:ss.SSS  Z'
            )
        };
    } else if (type === 'selectedDays') {
        setDate(moment().format('YYYY-MM-DD'));
        scheduleData = {
            [scheduleType]: [selectedDays]
        };
    }

    return scheduleData;
};
