/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@emotion/react';
import { List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { MENU_OPEN } from 'store/actions';
import { getCapitalize } from 'utils/share';
import { getAllChannels } from '../AddStandUp/channelSlice';
import { getAllStandUp } from '../AddStandUp/standupSlice';

const ListStandUps = ({ item }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { standUps } = useSelector((state) => state.standups);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getAllStandUp());
        dispatch(getAllChannels());
    }, []);

    const handleNavigation = (id) => {
        dispatch({ type: MENU_OPEN, id: 'listStandups' });
        navigate(`/dashboard/standup/${id}/view`);
    };

    const standUpsList = JSON.parse(JSON.stringify(standUps)).sort((a, b) => {
        let x = a?.name.toLowerCase();
        let y = b?.name.toLowerCase();
        if (x < y) {
            return -1;
        }
        if (x > y) {
            return 1;
        }
        return 0;
    });
    return (
        <List
            subheader={
                item.title && (
                    <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                        {item.title}
                        {item.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {item.caption}
                            </Typography>
                        )}
                    </Typography>
                )
            }
        >
            <Stack>
                {standUpsList &&
                    standUpsList?.map((standups, i) => (
                        <List key={standups.id}>
                            <ListItem disablePadding onClick={() => handleNavigation(standups.id)}>
                                <ListItemButton
                                    sx={{
                                        borderRadius: 2
                                    }}
                                >
                                    <ListItemText primary={getCapitalize(standups.name)} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    ))}
            </Stack>
        </List>
    );
};

export default ListStandUps;
