import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import {
    Box,
    Button,
    Card,
    Stack,
    CircularProgress,
    FormControlLabel,
    Grid,
    Popover,
    TextField,
    Typography,
    IconButton
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { DateRangePicker } from 'materialui-daterange-picker';
import { IconCaretDown, IconChevronDown, IconChevronUp } from '@tabler/icons';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { dateFormat } from 'utils/share';
import DatePicker from 'ui-component/DatePicker';
import { WeekDay } from 'utils/common';
const InsightsDateRange = ({ setSelectedDateRange, standupDetail }) => {
    const [dateRange, setDateRange] = React.useState({
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [notSchedule, setNotSchedule] = React.useState([]);
    const theme = useTheme();
    const [date, setDate] = React.useState();

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (standupDetail?.id)
            setNotSchedule(WeekDay.filter((item) => !standupDetail?.scheduleDays.includes(item.value)).map((schedule) => schedule.value));
    }, [standupDetail]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const checkPreviousDateIsSchedule = (date) => {
        return notSchedule.includes(moment(date).isoWeekday())
            ? checkPreviousDateIsSchedule(moment(date).subtract(1, 'days').format('YYYY-MM-DD'))
            : date;
    };
    const checkNextDateIsSchedule = (date) => {
        return notSchedule.includes(moment(date).isoWeekday())
            ? checkNextDateIsSchedule(moment(date).add(1, 'days').format('YYYY-MM-DD'))
            : date;
    };

    const navigatePreviousDate = () => {
        setSelectedDateRange({
            startDate: checkPreviousDateIsSchedule(moment(dateRange.startDate).subtract(1, 'days').format('YYYY-MM-DD')),
            endDate: checkPreviousDateIsSchedule(moment(dateRange.endDate).subtract(1, 'days').format('YYYY-MM-DD'))
        });
        setDateRange((prevValue) => ({
            startDate: checkPreviousDateIsSchedule(moment(prevValue.startDate).subtract(1, 'days').format('YYYY-MM-DD')),
            endDate: checkPreviousDateIsSchedule(moment(prevValue.endDate).subtract(1, 'days').format('YYYY-MM-DD'))
        }));
    };
    const navigateNextDate = () => {
        setSelectedDateRange({
            startDate: checkNextDateIsSchedule(moment(dateRange.startDate).add(1, 'days').format('YYYY-MM-DD')),
            endDate: checkNextDateIsSchedule(moment(dateRange.endDate).add(1, 'days').format('YYYY-MM-DD'))
        });
        setDateRange((prevValue) => ({
            startDate: checkNextDateIsSchedule(moment(prevValue.startDate).add(1, 'days').format('YYYY-MM-DD')),
            endDate: checkNextDateIsSchedule(moment(prevValue.endDate).add(1, 'days').format('YYYY-MM-DD'))
        }));
    };

    const handleApplyDate = () => {
        setSelectedDateRange(dateRange);
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (dateRange.startDate) {
            if (dateRange.label === 'Today') {
                setSelectedDateRange(dateRange);
                setDate(`${dateFormat(dateRange.startDate)}`);
            } else if (dateFormat(dateRange.startDate) === dateFormat(dateRange.endDate)) {
                return setDate(moment(dateFormat(dateRange.startDate)).format('dddd MMM DD, YYYY'));
            } else if (dateFormat(dateRange.startDate) && !dateFormat(dateRange.endDate)) setDate(`${dateFormat(dateRange.startDate)}`);
            else setDate(`${dateFormat(dateRange.startDate)} - ${dateFormat(dateRange.endDate)}`);
        }
    }, [dateRange]);

    return (
        <Grid container direction="row" spacing={2} alignItems="center" justifyContent="start" sx={{ width: '100%', paddingBottom: 2 }}>
            <Grid item xs={1} md={1} display="flex" justifyContent="center" alignItems="center">
                <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <IconButton
                        component="button"
                        size="large"
                        sx={{
                            border: ' 2px solid #b1b2b3',
                            borderRadius: '100%',

                            textAlign: 'center',
                            height: '48px',
                            width: '48px',
                            color: '#b1b2b3'
                        }}
                        onClick={navigatePreviousDate}
                    >
                        <ArrowBackOutlinedIcon fontSize="inherit" />
                    </IconButton>
                </Stack>
            </Grid>
            <Grid item xs={4} lg={4} md={6} sm={6}>
                <Button
                    disableElevation
                    fullWidth
                    onClick={handleClick}
                    size="large"
                    variant="outlined"
                    sx={{
                        color: theme.palette.grey[500],
                        fontWeight: 500,
                        backgroundColor: theme.palette.background.paper,
                        borderColor: theme.palette.grey[500],
                        borderRadius: 2,
                        textAlign: 'left',
                        margin: '10px 0px',
                        justifyContent: 'space-between',
                        ':hover': {
                            backgroundColor: theme.palette.background.paper,
                            borderColor: theme.palette.grey[600]
                        }
                    }}
                    disableTypography
                    disableRipple
                    disableFocusRipple
                    endIcon={<IconCaretDown fill={theme.palette.grey[500]} color={theme.palette.grey[500]} size="15px" />}
                >
                    {date}
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <DateRangePicker
                        open={open}
                        toggle={handleClose}
                        onChange={(range) => {
                            return setDateRange(range);
                        }}
                        closeOnClickOutside
                        wrapperClassName={'dateRangePicker'}
                    />
                    <Box
                        sx={{
                            justifyContent: 'center',
                            flexDirection: 'row',
                            alignItems: 'center',
                            display: 'flex',
                            flex: 1,
                            marginY: 2
                        }}
                    >
                        <Button variant="outlined" startIcon={<CloseIcon />} sx={{ marginX: 1, borderRadius: 6 }} onClick={handleClose}>
                            Clear
                        </Button>
                        <Button variant="contained" startIcon={<DoneIcon />} sx={{ marginX: 1, borderRadius: 6 }} onClick={handleApplyDate}>
                            Apply
                        </Button>
                    </Box>
                </Popover>
            </Grid>

            <Grid item xs={1} md={1} display="flex" justifyContent="center" alignItems="center">
                {moment(dateRange.startDate).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') ? null : (
                    <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <IconButton
                            component="button"
                            size="large"
                            sx={{
                                border: ' 2px solid #b1b2b3',
                                borderRadius: '100%',

                                textAlign: 'center',
                                height: '48px',
                                width: '48px',
                                color: '#b1b2b3'
                            }}
                            onClick={navigateNextDate}
                        >
                            <ArrowForwardOutlinedIcon fontSize="inherit" />
                        </IconButton>
                    </Stack>
                )}
            </Grid>
        </Grid>
    );
};

export default InsightsDateRange;
