import { axiosInstance, axiosInstanceSlack } from './api';

export const getAllAdmin = () => {
    let url = `/users?isAdmin=true`;

    return new Promise((resolve, reject) => {
        axiosInstance
            .get(url)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
};

export const addAdmin = ({ slackId }) => {
    let url = `/users/admin/add/${slackId}`;

    return new Promise((resolve, reject) => {
        axiosInstance
            .post(url)
            .then((response) => resolve(response?.data))
            .catch((err) => reject(err));
    });
};
export const removeAdmin = ({ slackId }) => {
    let url = `/users/admin/remove/${slackId}`;

    return new Promise((resolve, reject) => {
        axiosInstance
            .delete(url)
            .then((response) => resolve(response?.data))
            .catch((err) => reject(err));
    });
};
