import React from 'react';
import { Stack, Typography } from '@mui/material';
import { convertTimeFormatTo12Hour, getScheduleType, getUserTimeZone } from 'utils/share';
import ScheduleDetails from './ScheduleDetails';

const Schedule = ({ scheduleData: { dayList, time, timeZone, scheduleType, scheduleTime } }) => {
    const times = () => {
        let newTimeZone;
        if (timeZone === '' || getUserTimeZone() === timeZone) {
            newTimeZone = `user's local timezone`;
        } else {
            newTimeZone = timeZone;
        }

        if (time && newTimeZone)
            return `at ${convertTimeFormatTo12Hour(time)}${scheduleType === 'onetime' ? ' (One time only)' : ''}, in ${newTimeZone}.`;
    };
    return (
        <Stack direction="row" alignItems="center" flexWrap={'wrap'} spacing={0.5}>
            <ScheduleDetails dayList={dayList} scheduleType={scheduleType} scheduleTime={scheduleTime} />
            <Typography variant="h5"> {time && times()}</Typography>
        </Stack>
    );
};

export default Schedule;
