import fullLogo from 'assets/images/tixobot-full-logo.png';

const Logo = () => {
    return (
        <div className="header-logo">
            <img src={fullLogo} alt="tixobot" height="50px" />
        </div>
    );
};

export default Logo;
