import { useTheme } from '@mui/material/styles';
const useStyle = ({ errors, touched }) => {
    const theme = useTheme();
    return {
        broadcastChannelSXProps: {
            '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                background: '#ff00001c',
                border: '2px solid red'
            },
            '& .MuiFormHelperText-root': { fontWeight: 500, fontSize: '14px' }
        },
        formNameSXProps: { marginLeft: '14px', fontWeight: 500, fontSize: '14px' },
        standupNameSXProps: {
            width: '100%',
            pr: 1,
            pl: 2,
            mt: 2,
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                background: '#ff00001c',
                border: '2px solid red'
            }
        }
    };
};

export default useStyle;
