import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    name: Yup.string().max(50).required('* Name is required'),
    introMessage: Yup.string().max(200, '* Intro Message must be 200 characters or less'),
    outroMessage: Yup.string().max(200, '* Outro Message must be 200 characters or less'),
    broadcastChannels: Yup.array().min(1, '* Please choose at least one channel').required('* BroadcastChannels is required'),
    questionsList: Yup.array()
        .of(
            Yup.object().shape({
                title: Yup.string()
                    .max(200, 'Questions  must be 200 characters or less')
                    .required('Questions can not be empty, please type something or delete it.')
            })
        )
        .required('* BroadcastChannels is required')
});
