import { SLACK_BOT_TOKEN } from 'utils/constant';
import { axiosInstance, axiosInstanceSlack } from './api';

export const getChannels = () => {
    let url = `/channels`;
    return new Promise((resolve, reject) => {
        axiosInstance
            .get(url)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
};
