import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField, Box } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';

const DatePicker = ({ setDate, date }) => {
    const [value, setValue] = React.useState(date ?? moment().format('YYYY-MM-DD'));

    const handleChange = (newValue) => {
        setValue(newValue);
        setDate(moment(newValue).format('YYYY-MM-DD'));
    };
    return (
        <Box sx={{ marginTop: '15px' }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker value={value} onChange={handleChange} renderInput={(params) => <TextField {...params} />} />
            </LocalizationProvider>
        </Box>
    );
};

export default DatePicker;
