import React from 'react';
import { Stack, Box } from '@mui/material';
import PropTypes from 'prop-types';

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Stack
            role="tabpanel"
            hidden={value !== index}
            id={`standup-tabpanel-${index}`}
            aria-labelledby={`standup-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ padding: '30px 0' }}>{children}</Box>}
        </Stack>
    );
};

export default TabPanel;

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};
