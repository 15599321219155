import { SLACK_BOT_TOKEN } from 'utils/constant';
import { axiosInstanceSlack } from './api';

export const getAllParticipants = () => {
    let url = `/users.list`;
    const form = new FormData();
    form.append('token', SLACK_BOT_TOKEN);
    return new Promise((resolve, reject) => {
        axiosInstanceSlack
            .post(url, form)
            .then((response) => resolve(response?.data))
            .catch((err) => reject(err));
    });
};

export const getChannelParticipants = (channel, cursor) => {
    let url = `/conversations.members?channel=${channel}`;
    if (cursor) url += '&cursor=' + cursor;
    const form = new FormData();
    form.append('token', SLACK_BOT_TOKEN);
    return new Promise((resolve, reject) => {
        axiosInstanceSlack
            .post(url, form)
            .then((response) => resolve(response?.data))
            .catch((err) => reject(err));
    });
};
