import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getChannels } from 'services/channel.services';

const initialState = {
    channels: [],
    error: '',
    loading: false
};

export const getAllChannels = createAsyncThunk('getAllChannels', async () => {
    const response = await getChannels();

    return response.data;
});

export const channelSlice = createSlice({
    name: 'channels',
    initialState,

    reducers: {},

    extraReducers: {
        [getAllChannels.pending]: (state) => {
            state.loading = true;
        },

        [getAllChannels.fulfilled]: (state, action) => {
            console.log('hello', action.payload);
            state.channels = action.payload.data;

            state.loading = false;
        },

        [getAllChannels.rejected]: (state, { error }) => {
            state.error = error;
            state.loading = false;
        }
    }
});

export default channelSlice.reducer;
