import { Box, TextField, Paper, InputLabel, Autocomplete, Tooltip, Avatar, Typography, Checkbox, Skeleton, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import { IconPencil, IconHelp } from '@tabler/icons';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { getAllParticipants, getChannelParticipants } from 'services/participants.services';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import useParticipants from './useParticipants';
import { isLastPermission } from 'utils/share';
import ConfirmModal from 'ui-component/ConfirmModal';
import WarningIcon from '@mui/icons-material/Warning';

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2
    }
}));

const Participation = ({ isEditStandUp, standupDetail }) => {
    const {
        participants,
        setParticipants,
        Pop,
        channelMembers,
        nextCursor,
        values,
        theme,
        handleChange,
        handleBlur,
        touched,
        errors,
        setFieldValue,
        mapChannelParticipants,
        mapCurrentParticipant,
        checkBoxHandler,
        handleManageConfirm,
        handleRemoveConfirm,
        removeModal,
        setRemoveModal,
        manageModal,
        setManageModal,
        permissions,
        currentUser,
        isLoading
    } = useParticipants({ isEditStandUp, standupDetail });

    const CustomPaper = (props) => {
        return <Paper elevation={5} {...props} style={{ borderColor: 'red', borderRadius: `${theme?.customization?.borderRadius}px` }} />;
    };
    const { isReport, isManage } = permissions;
    return (
        <MainCard border={false} contentSX={{ p: 0 }}>
            <Box>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <InputLabel sx={{ marginRight: 0.5 }}>Add participants</InputLabel>
                        <Tooltip
                            title="The members you select will participate in this standup. Turn Sync on to include everyone in the broadcast channel."
                            placement="top"
                        >
                            <div>
                                <IconHelp stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                            </div>
                        </Tooltip>
                    </Box>
                    {/* TODO : tempaory disable   */}
                    {/* <FormGroup>
                        <FormControlLabel
                            control={
                                <Android12Switch
                                    checked={values.isSyncToChannel}
                                    onChange={(event, value) => {
                                        setFieldValue('isSyncToChannel', value);
                                        if (value) {
                                            const channelParticipants = mapChannelParticipants();
                                            setFieldValue('participants', channelParticipants);
                                        } else {
                                            const channelParticipants = mapCurrentParticipant();
                                            setFieldValue('participants', channelParticipants);
                                        }
                                    }}
                                />
                            }
                            label="Sync to channel"
                        />
                    </FormGroup> */}
                </Box>

                {!values.isSyncToChannel && (
                    <Autocomplete
                        multiple
                        name="members"
                        // PopperComponent={Pop}
                        PaperComponent={CustomPaper}
                        id="combo-box"
                        options={participants}
                        onChange={(event, value) => {
                            const valuesData = value.map((user) => ({
                                ...user,
                                permissions: !user.slackId ? { ...permissions, slackId: user.id } : { ...user.permissions }
                            }));
                            var participation = values?.participants;
                            var newParticipation = valuesData.filter(
                                ({ id: valueID }) => !participation.some(({ id: participantID }) => valueID === participantID)
                            );
                            var finalParticipation = participation.concat(newParticipation);
                            setFieldValue('participants', [...finalParticipation]);
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        sx={{ width: '100%', my: 2 }}
                        getOptionLabel={(option) => option.real_name}
                        renderInput={(params) => {
                            delete params?.InputProps?.startAdornment;
                            return <TextField {...params} placeholder="Add participants...." />;
                        }}
                        value={values.participants}
                    />
                )}
                {values?.participants?.length > 0 ? (
                    <>
                        {values?.participants?.map((member, index) => (
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ mt: 2 }}
                                key={member.id}
                            >
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Box>
                                        <Avatar alt={member?.real_name} src={member?.profile?.image_72} sx={{ width: 64, height: 64 }} />
                                    </Box>
                                    <Box sx={{ ml: 2 }}>
                                        <Typography variant="h3" sx={{ color: '#626262', mb: 0.5 }}>
                                            {member?.real_name}
                                        </Typography>
                                        <Typography component="span" variant="h4" sx={{ fontWeight: 100, color: '#9e9e9e' }}>
                                            @{member?.profile?.display_name.length ? member?.profile?.display_name : member?.name}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <FormControlLabel disabled control={<Checkbox defaultChecked name="isView" />} label="View" />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultChecked
                                                disabled={
                                                    member.permissions?.isReport && isLastPermission(values?.participants, 'isReport')
                                                }
                                                checked={member.permissions?.isReport}
                                                onChange={(event) =>
                                                    checkBoxHandler(
                                                        event,

                                                        member.id
                                                    )
                                                }
                                                name="isReport"
                                            />
                                        }
                                        label="Report"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={
                                                    member.permissions?.isManage && isLastPermission(values?.participants, 'isManage')
                                                }
                                                checked={member.permissions?.isManage}
                                                onChange={(event) => {
                                                    if (member.id === currentUser.slackUserId && member.permissions?.isManage) {
                                                        setManageModal(!manageModal);
                                                    } else {
                                                        checkBoxHandler(event, member.id);
                                                    }
                                                }}
                                                name="isManage"
                                            />
                                        }
                                        label="Manage"
                                    />
                                    {member.permissions?.isManage && isLastPermission(values?.participants, 'isManage') ? (
                                        <div></div>
                                    ) : (
                                        <FormControlLabel
                                            sx={
                                                values.isSyncToChannel
                                                    ? {
                                                          display: 'none'
                                                      }
                                                    : {}
                                            }
                                            onClick={() => {
                                                if (member.id === currentUser.slackUserId) {
                                                    setRemoveModal(!removeModal);
                                                } else {
                                                    const newParticipation = values?.participants;
                                                    newParticipation?.splice(index, 1);
                                                    setFieldValue('participants', newParticipation);
                                                }
                                            }}
                                            control={<DeleteIcon />}
                                            label="Remove"
                                        />
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </>
                ) : (
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box>
                                <Skeleton animation="wave" variant="circular" width={64} height={64} />
                            </Box>
                            <Box sx={{ ml: 2 }}>
                                <Typography variant="h3" sx={{ color: '#626262', mb: 0.5 }}>
                                    <Skeleton width="800px" />
                                </Typography>
                                <Typography component="span" variant="h4" sx={{ fontWeight: 100, color: '#9e9e9e' }}>
                                    <Skeleton animation="wave" height={10} width="50%" />
                                </Typography>
                            </Box>
                        </Box>
                        <Stack direction="row" justifyContent="space-evenly" alignItems="flex-end" spacing={3}>
                            <Skeleton animation="wave" variant="rectangular" width={24} height={24} />
                            <Skeleton animation="wave" variant="rectangular" width={24} height={24} />
                            <Skeleton animation="wave" variant="rectangular" width={24} height={24} />
                        </Stack>
                    </Box>
                )}
            </Box>
            <ConfirmModal
                open={manageModal}
                icon={<WarningIcon fontSize="large" sx={{ color: '#fff' }} />}
                toggle={() => setManageModal(!manageModal)}
                title={'Are You Sure?'}
                content={'If you remove the manage privilege you will no longer be able to manage this standup.'}
                confirmLabel={'I am 100% sure I want my privileges removed'}
                confirmCheckbox
                cancelButton={'Cancel'}
                actionButtonColor={'error'}
                actionButton={"I'm sure, remove my privileges"}
                actionClick={handleManageConfirm}
            />
            <ConfirmModal
                open={removeModal}
                icon={<WarningIcon fontSize="large" sx={{ color: '#fff' }} />}
                toggle={() => setRemoveModal(!removeModal)}
                title={'Are You Sure?'}
                content={
                    'If you proceed you will no longer have access to this standup. If your intension was to avoid posting updates, you can instead try removing the report privilege from yourself.'
                }
                confirmLabel={'I am 100% sure I want to be removed'}
                confirmCheckbox
                cancelButton={'Cancel'}
                actionButtonColor={'error'}
                actionButton={"I'm sure, remove me"}
                actionClick={handleRemoveConfirm}
            />
        </MainCard>
    );
};

export default Participation;
