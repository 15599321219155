import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    Stack,
    CircularProgress,
    FormControlLabel,
    Grid,
    Popover,
    TextField,
    Typography,
    Divider,
    IconButton,
    Skeleton,
    useTheme
} from '@mui/material';
import Avatar from 'ui-component/extended/Avatar';
import moment from 'moment';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import SkeletonInsightsForOneDay from './SkeletonInsightsForOneDay';
import InsightsEmptyData from './InsightsEmptyData';
import { seperateResponseAnswerString } from 'utils/share';

const InsightsForOneDay = ({ standupDetail }) => {
    const { singleDayInsights, insightsLoading } = useSelector((state) => state.insights);

    const theme = useTheme();
    return (
        <>
            {!insightsLoading && singleDayInsights?.Questions?.length > 0 ? (
                <Box>
                    {singleDayInsights?.Questions?.map((questionData, index) => (
                        <MainCard
                            key={index}
                            border={false}
                            headerSX={{ padding: '10px 24px 10px 24px' }}
                            contentSX={{ padding: '0px 24px 24px 24px' }}
                            sx={{
                                mb: 3,
                                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)',
                                ':hover': {
                                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)'
                                }
                            }}
                            title={
                                <Grid
                                    item
                                    sx={{
                                        height: '70px',
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'center'
                                    }}
                                >
                                    <FiberManualRecordIcon
                                        fontSize="small"
                                        sx={{
                                            fontSize: 20,
                                            color: questionData?.answer[0]?.Question?.color,
                                            marginRight: 2,
                                            ml: 2
                                        }}
                                    />{' '}
                                    <Typography variant="h3">{questionData?.question}</Typography>
                                </Grid>
                            }
                        >
                            <Grid item sx={{ maxHeight: '457px', overflow: 'auto' }}>
                                <Grid
                                    item
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    {singleDayInsights?.Questions?.map((data, i) => {
                                        return (
                                            <Grid
                                                key={i}
                                                container
                                                direction="column"
                                                sx={{
                                                    width: '100%'
                                                }}
                                            >
                                                {data?.answer?.map((item) => {
                                                    return (
                                                        item?.Question?.title === questionData?.question && (
                                                            <Grid
                                                                key={item?.id}
                                                                container
                                                                direction="row"
                                                                sx={{ borderBottom: '1px solid #eeeeee' }}
                                                            >
                                                                <Grid item xs={3} display="flex" justifyContent="center" alignItems="start">
                                                                    <Avatar
                                                                        src={item?.User?.image72}
                                                                        sx={{
                                                                            width: 56,
                                                                            height: 56,

                                                                            [theme.breakpoints.down('lg')]: {
                                                                                width: 45,
                                                                                height: 45
                                                                            },
                                                                            mt: 2
                                                                        }}
                                                                        alt="ss"
                                                                    ></Avatar>
                                                                </Grid>
                                                                <Grid item xs={8} md={8}>
                                                                    <Box
                                                                        display="flex"
                                                                        flexDirection="row"
                                                                        alignItems="center"
                                                                        justifyContent="start"
                                                                        sx={{ p: 3, pt: 1 }}
                                                                    >
                                                                        <Typography
                                                                            variant="h4"
                                                                            sx={{
                                                                                mr: 1,
                                                                                [theme.breakpoints.down('lg')]: {
                                                                                    fontSize: '13px'
                                                                                }
                                                                            }}
                                                                        >
                                                                            {item?.User?.firstName + ' ' + item?.User?.lastName}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant={'div'}
                                                                            sx={{
                                                                                [theme.breakpoints.down('lg')]: {
                                                                                    fontSize: '10px'
                                                                                }
                                                                            }}
                                                                        >
                                                                            - {moment(item?.reportedAt).format('h:mm a, MMM DD')}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="start"
                                                                        justifyContent="start"
                                                                        sx={{ pl: 3 }}
                                                                    >
                                                                        <Typography variant="div" sx={{ mr: 1, mb: 2 }}>
                                                                            {seperateResponseAnswerString(item?.answerText)}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    );
                                                })}
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                display="flex"
                                alignItems="end"
                                justifyContent="flex-end"
                                sx={{
                                    height: '70px',
                                    display: 'flex',

                                    justifyContent: 'end',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="div" color="GrayText" sx={{ mr: 4, fontSize: '18px' }}>
                                    {Object.keys(questionData?.userCount)?.length} total responses
                                </Typography>
                            </Grid>
                        </MainCard>
                    ))}
                </Box>
            ) : !insightsLoading && singleDayInsights?.Questions?.length === 0 ? (
                <InsightsEmptyData />
            ) : (
                <SkeletonInsightsForOneDay />
            )}
        </>
    );
};

export default InsightsForOneDay;
