import { IconUsers } from '@tabler/icons';

const icons = { IconUsers };

// ==============================|| ADMINS MENU ITEMS ||============================== //

const adminDashboard = {
    id: 'adminDashboard',
    title: 'Admins',
    type: 'admins',
    url: '/admin/dashboard',
    icon: icons.IconUsers
};

export default adminDashboard;
