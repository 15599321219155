// assets
import { IconKey } from '@tabler/icons';

// constant
const icons = {
    IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const listStandUps = {
    id: 'listStandups',
    title: 'All Standups',
    type: 'standupsList'
};

export default listStandUps;
