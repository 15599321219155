import { IconButton, List, ListItem, ListItemText, ListSubheader, Menu, MenuItem, Paper, Tooltip } from '@mui/material';
import PopupState, { bindTrigger, bindMenu, bindHover } from 'material-ui-popup-state';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import QuestionsTypeModal from '../questionsTypeDialog/QuestionsTypeDialog';
import ColorPopover from '../questionsTypeDialog/ColorPopover';

const QuestionPopupDetails = ({ icon, list, item, index, onDeleteQuestionHandler, onColorChange, onQuestionTypeChange }) => {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openColorPopover = Boolean(anchorEl);
    const handleClickColorPopover = () => {
        setAnchorEl(document.getElementById('questionPopover'));
    };

    const handleCloseColorPopover = () => {
        setAnchorEl(null);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <>
                    <IconButton id="questionPopover" variant="contained" {...bindHover(popupState)} sx={{ p: '10px' }} aria-label="search">
                        <SettingsIcon />
                    </IconButton>
                    <HoverMenu {...bindMenu(popupState)}>
                        <Paper sx={{ p: 2 }}>
                            <MenuItem onClick={handleClickOpen}>Pick type</MenuItem>
                            <MenuItem
                                aria-controls={open ? 'color-picker' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClickColorPopover}
                            >
                                Pick color
                            </MenuItem>
                            {list.length > 1 && (
                                <MenuItem onClick={onDeleteQuestionHandler} sx={{ color: 'red' }}>
                                    Delete
                                </MenuItem>
                            )}
                        </Paper>
                    </HoverMenu>
                    <ColorPopover
                        onColorChange={onColorChange}
                        open={openColorPopover}
                        anchorEl={anchorEl}
                        itemIndex={index}
                        item={item}
                        handleClose={handleCloseColorPopover}
                    />

                    <QuestionsTypeModal
                        index={index}
                        handleClose={handleClose}
                        open={open}
                        item={item}
                        onQuestionTypeChange={onQuestionTypeChange}
                    />
                </>
            )}
        </PopupState>
    );
};

export default QuestionPopupDetails;
