import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetTimeline, standUpTimeLine } from 'views/pages/AddStandUp/standupSlice';
import { resetMultipleDaysInsights } from 'views/pages/Insights/insightsSlice';
import TimeLineFilter from '../components/TimelineFilter/TimeLineFilter';
import TimeLineResponseData from '../components/TimelineResponseData/TimeLineResponseData';

const TimeLine = () => {
    const dispatch = useDispatch();
    const [filterObject, setFilterObject] = useState({ page: 1 });
    const { standupDetail } = useSelector((state) => state.standups);
    const { isMultipleDaysTimeline } = useSelector((state) => state.insights);
    useEffect(() => {
        if (isMultipleDaysTimeline?.isTimelineClick) {
            dispatch(
                standUpTimeLine({
                    page: 1,
                    Questions: [isMultipleDaysTimeline?.questionId],
                    fromDate: isMultipleDaysTimeline.fromDate,
                    toDate: isMultipleDaysTimeline.toDate,
                    standupId: isMultipleDaysTimeline.standupId
                })
            );
        } else if (filterObject.standupId) {
            dispatch(standUpTimeLine(filterObject));
        }
    }, [filterObject, isMultipleDaysTimeline?.isTimelineClick]);
    return (
        <Grid container>
            <Grid item xs={6} md={7}>
                <TimeLineResponseData filterObject={filterObject} setFilterObject={setFilterObject} />
            </Grid>
            <Grid item xs={6} md={5}>
                {standupDetail.id ? (
                    <TimeLineFilter
                        setFilterObject={setFilterObject}
                        standupId={standupDetail.id}
                        Participants={standupDetail?.Participants?.filter((item) => item?.isReport)}
                        Questions={standupDetail.Questions}
                    />
                ) : (
                    <></>
                )}
            </Grid>
        </Grid>
    );
};

export default TimeLine;
