import React from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';
import { getDaysList, getScheduleType } from 'utils/share';

const ScheduleDetails = ({ dayList, scheduleType, scheduleTime }) => {
    if (scheduleType === 'onetime') {
        return <Typography variant="h5" sx={{ py: 0.5 }}>{`Scheduled for ${moment(scheduleTime).format('ddd, MMM Do,YYYY')}`}</Typography>;
    } else if (scheduleType === 'weekly') {
        let dayListString = [...dayList[0]].sort((a, b) => a - b);

        if (dayListString.toString() === [1, 2, 3, 4, 5].toString()) {
            return (
                <Typography variant="h5" sx={{ py: 0.5 }}>
                    {'Weekly from Monday to Friday,'}
                </Typography>
            );
        } else if (dayListString.toString() === [0, 1, 2, 3, 4, 5, 6].toString()) {
            return (
                <Typography variant="h5" sx={{ py: 0.5 }}>
                    {'Daily,  '}
                </Typography>
            );
        } else {
            let newArray = getDaysList(dayList[0]);
            const items = newArray.slice(0, newArray.length - 1);

            let days = items.join(', ');

            return (
                <Typography variant="h5" sx={{ py: 0.5 }}>
                    {'Weekly on ' + '' + `${days.length ? days + ' ' + 'and' : ''}` + ' ' + newArray[newArray.length - 1] + ', '}
                </Typography>
            );
        }
    } else if (scheduleType === 'first_days_month') {
        let newArray = getDaysList(dayList[0]);
        const items = newArray.slice(0, newArray.length - 1);
        let days = items.join(', ');
        return (
            <Typography variant="h5" sx={{ py: 0.5 }}>
                {'First ' + '' + `${days.length ? days + '' + ',' : ''}` + ' ' + newArray[newArray.length - 1] + ' of every month,'}
            </Typography>
        );
    } else if (scheduleType === 'last_days_month') {
        let newArray = getDaysList(dayList[0]);
        const items = newArray.slice(0, newArray.length - 1);
        let days = items.join(', ');
        return (
            <Typography variant="h5" sx={{ py: 0.5 }}>
                {'Last ' + '' + `${days.length ? days + '' + ',' : ''}` + ' ' + newArray[newArray.length - 1] + ' of every month,'}
            </Typography>
        );
    } else if (scheduleType === '2_week' || scheduleType === '3_week' || scheduleType === '4_week') {
        return (
            <Typography variant="h5" sx={{ py: 0.5 }}>
                {'On various days in a '}
                {getScheduleType(scheduleType)}
                {','}
            </Typography>
        );
    }
};
export default ScheduleDetails;
