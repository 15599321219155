// assets
import { IconCirclePlus } from '@tabler/icons';

// constant
const icons = { IconCirclePlus };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const newStandup = {
    id: 'newStandup',
    title: 'Create New Standup',
    type: 'button',
    url: '/standup/create',
    icon: icons.IconCirclePlus
};

export default newStandup;
