import { Typography, Stack, Box, Grid, useTheme, Avatar } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import insightsEmptyData from 'assets/images/users/insightsNodata.jpg';

const InsightsEmptyData = () => {
    const theme = useTheme();
    return (
        <Box>
            <MainCard
                border={false}
                sx={{
                    mb: 3,
                    [theme.breakpoints.down('lg')]: {
                        height: '368px'
                    },
                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
                    ':hover': {
                        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'
                    },
                    [theme.breakpoints.down('lg')]: {
                        height: '368px'
                    }
                }}
            >
                <Grid
                    item
                    sx={{
                        height: '457px',
                        overflow: 'auto'
                    }}
                >
                    <Stack justifyContent="center" alignItems="center" spacing={3}>
                        <Stack
                            alignItems="center"
                            sx={{
                                [theme.breakpoints.down('lg')]: {
                                    width: '50%',
                                    height: '50%'
                                },
                                width: '40%',
                                height: '40%'
                            }}
                        >
                            <img src={insightsEmptyData} alt="insightsEmptyData" width="100%" height="100%" />
                        </Stack>
                        <Typography
                            variant="h1"
                            sx={{
                                [theme.breakpoints.down('lg')]: {
                                    fontSize: '20px'
                                }
                            }}
                        >
                            Your Dates Look Quiet
                        </Typography>
                        <Typography variant="h5" alignItems="center" sx={{ textAlign: 'center' }}>
                            There are no reports in your selected dates. Please
                            <br /> select a new day or period, and Tixobot will look again.
                        </Typography>
                    </Stack>
                </Grid>
            </MainCard>
        </Box>
    );
};

export default InsightsEmptyData;
