import { Skeleton, Grid, Divider } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';

const SkeletonInsightsForOneDay = () => {
    return (
        <>
            <MainCard
                border={false}
                headerSX={{
                    p: 4
                }}
                sx={{
                    mb: 3,

                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)',
                    ':hover': {
                        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)'
                    }
                }}
                title={
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        <Skeleton variant="text" sx={{ width: '50%', fontSize: '1rem' }} />
                    </Grid>
                }
            >
                <Grid item sx={{ mb: 3 }}>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Grid container direction="column" sx={{ width: '85%' }}>
                            <Grid container direction="row">
                                <Grid item xs={3} display="flex" justifyContent="center" alignItems="start">
                                    <Skeleton variant="circular" width={70} height={70} />
                                </Grid>
                                <Grid item xs={6} md={8} sx={{ ml: 3 }}>
                                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '1rem', mb: 2 }} />

                                    <Skeleton variant="text" sx={{ width: '100%', fontSize: '1rem', mb: 2 }} />

                                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '1rem', mb: 2 }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Grid container direction="column" sx={{ width: '85%' }}>
                            <Grid container direction="row">
                                <Grid item xs={3} display="flex" justifyContent="center" alignItems="start">
                                    <Skeleton variant="circular" width={70} height={70} />
                                </Grid>
                                <Grid item xs={6} md={8} sx={{ ml: 3 }}>
                                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '1rem', mb: 2 }} />

                                    <Skeleton variant="text" sx={{ width: '100%', fontSize: '1rem', mb: 2 }} />

                                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '1rem', mb: 2 }} />
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid
                                item
                                display="flex"
                                alignItems="end"
                                justifyContent="flex-end"
                                sx={{
                                    height: '70px',
                                    display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'center'
                                }}
                            >
                                <Skeleton variant="text" sx={{ width: '50%', fontSize: '2rem' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
            <MainCard
                border={false}
                headerSX={{
                    p: 4
                }}
                sx={{
                    mb: 3,

                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
                    ':hover': {
                        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'
                    }
                }}
                title={
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        <Skeleton variant="text" sx={{ width: '50%', fontSize: '1rem' }} />
                    </Grid>
                }
            >
                <Grid item sx={{ mb: 3 }}>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Grid container direction="column" sx={{ width: '85%' }}>
                            <Grid container direction="row">
                                <Grid item xs={3} display="flex" justifyContent="center" alignItems="start">
                                    <Skeleton variant="circular" width={70} height={70} />
                                </Grid>
                                <Grid item xs={6} md={8} sx={{ ml: 3 }}>
                                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '1rem', mb: 2 }} />

                                    <Skeleton variant="text" sx={{ width: '100%', fontSize: '1rem', mb: 2 }} />

                                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '1rem', mb: 2 }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Grid container direction="column" sx={{ width: '85%' }}>
                            <Grid container direction="row">
                                <Grid item xs={3} display="flex" justifyContent="center" alignItems="start">
                                    <Skeleton variant="circular" width={70} height={70} />
                                </Grid>
                                <Grid item xs={6} md={8} sx={{ ml: 3 }}>
                                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '1rem', mb: 2 }} />

                                    <Skeleton variant="text" sx={{ width: '100%', fontSize: '1rem', mb: 2 }} />

                                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '1rem', mb: 2 }} />
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid
                                item
                                display="flex"
                                alignItems="end"
                                justifyContent="flex-end"
                                sx={{
                                    height: '70px',
                                    display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'center'
                                }}
                            >
                                <Skeleton variant="text" sx={{ width: '50%', fontSize: '2rem' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </>
    );
};

export default SkeletonInsightsForOneDay;
