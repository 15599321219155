import { Skeleton, Grid } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';

const InsightsParticipantsSkeleton = () => {
    return (
        <MainCard
            border={false}
            sx={{
                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)',
                ':hover': {
                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)'
                }
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Skeleton variant="text" sx={{ width: '35%', fontSize: '2rem', mb: 2 }} />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Skeleton variant="circular" width={227} height={227} />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '1rem', mb: 2 }} />
                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '1rem', mb: 2 }} />
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default InsightsParticipantsSkeleton;
