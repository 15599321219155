// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import NavItem from './NavItem';
import ListStandUps from 'views/pages/ListStandUps/ListStandUps';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { isAdmin } = useSelector((state) => state.login);
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            case 'standupsList':
                return <ListStandUps key={item.id} item={item} />;
            case 'button':
                return <NavItem key={item.id} item={item} type="button" />;
            case 'admins':
                if (isAdmin) {
                    return <NavItem key={item.id} item={item} type="button" />;
                }
                break;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
