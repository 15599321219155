import { axiosInstance } from './api';

export const login = ({ authCode, redirectionUrl }) => {
    let url = `/login?code=${authCode}&slackRedirectURI=${redirectionUrl}`;

    return new Promise((resolve, reject) => {
        axiosInstance
            .post(url)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
};
