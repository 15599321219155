import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    Popover,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { DateRangePicker } from 'materialui-daterange-picker';
import { IconCaretDown, IconChevronDown, IconChevronUp } from '@tabler/icons';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { dateFormat, getDate } from 'utils/share';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { resetTimeline } from '../../../AddStandUp/standupSlice';
import { useDispatch } from 'react-redux';
import { standUpsTimeLine } from 'services/standup.services';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const TimeLineFilter = ({ standupId, Participants, Questions, setFilterObject }) => {
    const { isMultipleDaysTimeline } = useSelector((state) => state.insights);
    const availableP = Participants.map((item) => {
        return item?.User?.slackUserId;
    });
    const [checkedP, setCheckedP] = React.useState(availableP);

    let availableQ = isMultipleDaysTimeline?.isTimelineClick
        ? [isMultipleDaysTimeline?.questionId]
        : Questions.map((item) => {
              return item.id;
          });

    const [checkedQ, setCheckedQ] = React.useState(availableQ);
    const [dateRange, setDateRange] = React.useState({
        startDate: isMultipleDaysTimeline?.isTimelineClick
            ? isMultipleDaysTimeline.fromDate
            : moment().subtract(10, 'days').format('YYYY-MM-DD'),
        endDate: isMultipleDaysTimeline?.isTimelineClick ? isMultipleDaysTimeline?.toDate : moment().format('YYYY-MM-DD')
    });

    const [selectedDateRange, setSelectedDateRange] = React.useState(dateRange);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();
    const [date, setDate] = React.useState();
    const [showMore, setShowMore] = React.useState(true);
    const [itemsToShow, setItemsToShow] = React.useState(Participants?.length > 4 ? 4 : Participants?.length);
    const [selectAllP, setSelectAllP] = React.useState(true);
    const [selectAllQ, setSelectAllQ] = React.useState(true);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [exportLoading, setExportLoading] = React.useState(false);
    const [openExportDialog, setopenExportDialog] = React.useState(false);
    const [exportDialog, setExportDialog] = React.useState({
        title: '',
        message: ''
    });
    const dispatch = useDispatch();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (selectedDateRange && standupId) {
            dispatch(resetTimeline());

            setFilterObject({
                page: 1,
                Participants: checkedP,
                Questions: checkedQ,
                fromDate: getDate(selectedDateRange.startDate),
                toDate: getDate(selectedDateRange.endDate),
                standupId: standupId
            });
        }
    }, [checkedP, checkedQ, selectedDateRange, standupId, isMultipleDaysTimeline]);
    const handleApplyDate = () => {
        setSelectedDateRange(dateRange);
        setAnchorEl(null);
    };

    const handleShowMore = () => {
        if (showMore) {
            setItemsToShow(Participants.length);
        } else {
            setItemsToShow(4);
        }
        setShowMore(!showMore);
    };

    const handleToggleParticipants = (value) => () => {
        const currentIndex = checkedP.indexOf(value);
        const newChecked = [...checkedP];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setCheckedP(newChecked);
    };
    const handleToggleQuestions = (value) => () => {
        const currentIndex = checkedQ.indexOf(value);
        const newChecked = [...checkedQ];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedQ(newChecked);
    };

    const handleExportCSV = async () => {
        try {
            const finalBody = {
                Participants: checkedP,
                Questions: checkedQ,
                fromDate: getDate(dateRange.startDate),
                toDate: getDate(dateRange.endDate),
                standupId: standupId,
                isCSV: true
            };
            setExportLoading(true);
            const res = await standUpsTimeLine(finalBody);
            if (res?.data?.status === 'success') {
                setExportDialog({
                    title: 'Export Successfully',
                    message: 'Your request is being processed, you will receive the exported data in your inbox shortly.'
                });
            } else if (res.data.message.includes('not found')) {
                setExportDialog({
                    title: 'No Data Found',
                    message: ''
                });
            } else {
                setExportDialog({
                    title: 'Something went wrong',
                    message: ''
                });
            }

            setExportLoading(false);
            setopenExportDialog(true);
        } catch (err) {
            console.log(err);
            setExportDialog({
                title: 'Export Failed',
                message: ''
            });
            setExportLoading(false);
            setopenExportDialog(true);
        }
    };
    React.useEffect(() => {
        if (dateRange.startDate) {
            if (dateRange.label === 'Today') {
                setDate();
            } else setDate(`${dateFormat(dateRange.startDate)} - ${dateFormat(dateRange.endDate)}`);
        }
    }, [dateRange]);
    React.useEffect(() => {
        if (selectAllQ) {
            setCheckedQ(availableQ);
        } else {
            setCheckedQ([]);
        }
    }, [selectAllQ]);
    React.useEffect(() => {
        if (selectAllP) {
            setCheckedP(availableP);
        } else {
            setCheckedP([]);
        }
    }, [selectAllP]);
    const handleExportDialogClose = () => {
        setopenExportDialog(false);
    };
    return (
        <Card sx={{ backgroundColor: theme.palette.grey[100], width: '100%', padding: 2, maxWidth: '500px' }}>
            <>
                <Typography variant="h4" margin={'8px 0px'}>
                    Date Range
                </Typography>
                <Button
                    disableElevation
                    fullWidth
                    onClick={handleClick}
                    size="large"
                    variant="outlined"
                    sx={{
                        color: theme.palette.grey[500],
                        fontWeight: 500,
                        backgroundColor: theme.palette.background.paper,
                        borderColor: theme.palette.grey[500],
                        borderRadius: 2,
                        textAlign: 'left',
                        margin: '10px 0px',
                        justifyContent: 'space-between',
                        ':hover': {
                            backgroundColor: theme.palette.background.paper,
                            borderColor: theme.palette.grey[600]
                        }
                    }}
                    disableTypography
                    disableRipple
                    disableFocusRipple
                    endIcon={<IconCaretDown fill={theme.palette.grey[500]} color={theme.palette.grey[500]} size="15px" />}
                >
                    {date || 'EveryThing'}
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <DateRangePicker
                        open={open}
                        toggle={handleClose}
                        onChange={(range) => setDateRange(range)}
                        closeOnClickOutside
                        wrapperClassName={'dateRangePicker'}
                    />
                    <Box
                        sx={{
                            justifyContent: 'center',
                            flexDirection: 'row',
                            alignItems: 'center',
                            display: 'flex',
                            flex: 1,
                            marginY: 2
                        }}
                    >
                        <Button variant="outlined" startIcon={<CloseIcon />} sx={{ marginX: 1, borderRadius: 6 }} onClick={handleClose}>
                            Clear
                        </Button>
                        <Button variant="contained" startIcon={<DoneIcon />} sx={{ marginX: 1, borderRadius: 6 }} onClick={handleApplyDate}>
                            Apply
                        </Button>
                    </Box>
                </Popover>
                <Box
                    sx={{
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        alignItems: 'center',
                        display: 'flex',
                        flex: 1,
                        marginY: 1
                    }}
                >
                    <Typography variant="h4">Participants</Typography>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox edge="end" onChange={() => setSelectAllP(!selectAllP)} checked={selectAllP} size="small" />}
                        label="All"
                        labelPlacement="start"
                        sx={{ marginRight: 2 }}
                    />
                </Box>

                <List dense sx={{ width: '100%' }}>
                    {Participants?.slice(0, itemsToShow).map((value, index) => {
                        const labelId = `checkbox-list-secondary-label-${value}`;
                        return (
                            <ListItem
                                id={'timelineParticipant'}
                                key={index}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={handleToggleParticipants(value?.User?.slackUserId)}
                                        checked={checkedP.includes(value?.User?.slackUserId)}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        size="small"
                                    />
                                }
                                sx={{
                                    cursor: 'pointer'
                                }}
                                disablePadding
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', padding: 0.5, width: '85%' }} key={index}>
                                    <ListItemAvatar>
                                        <Avatar
                                            alt={`Avatar n°${value.User.name}`}
                                            src={value.User.image48}
                                            sx={{ width: 35, height: 35 }}
                                        />
                                    </ListItemAvatar>
                                    <Typography
                                        id={labelId}
                                        sx={{
                                            fontSize: 12,
                                            color: theme.palette.grey[600],
                                            fontWeight: 500
                                        }}
                                        noWrap
                                    >
                                        {`@${value.User.name}`}
                                    </Typography>
                                </Box>
                            </ListItem>
                        );
                    })}
                </List>
                {Participants?.length > 4 ? (
                    <Box
                        sx={{ alignItems: 'center', flexDirection: 'row', display: 'flex', marginY: 1, cursor: 'pointer' }}
                        onClick={handleShowMore}
                    >
                        <Typography variant="h6" sx={{ marginRight: 1 }}>
                            {showMore ? 'Show All' : 'Show Less'}
                        </Typography>
                        {showMore ? (
                            <IconChevronDown fill={theme.palette.grey[500]} color={theme.palette.grey[500]} size="15px" />
                        ) : (
                            <IconChevronUp fill={theme.palette.grey[500]} color={theme.palette.grey[500]} size="15px" />
                        )}
                    </Box>
                ) : null}
                <Box sx={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', display: 'flex', marginY: 1 }}>
                    <Typography variant="h4">Questions</Typography>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox edge="end" onChange={() => setSelectAllQ(!selectAllQ)} checked={selectAllQ} size="small" />}
                        label="All"
                        labelPlacement="start"
                        sx={{ marginRight: 2 }}
                    />
                </Box>
                <List dense sx={{ width: '100%', margin: 0 }}>
                    {Questions?.map((item, index) => {
                        const labelId = `checkbox-list-secondary-label-${item}`;

                        return (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={handleToggleQuestions(item.id)}
                                        checked={checkedQ.includes(item.id)}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        size="small"
                                    />
                                }
                                sx={{
                                    cursor: 'pointer',
                                    margin: 0
                                }}
                                disablePadding
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, width: '90%' }} key={index}>
                                    <FiberManualRecordIcon fontSize="small" sx={{ fontSize: 15, color: item.color, marginRight: 2 }} />
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            color: theme.palette.grey[600],
                                            fontWeight: 500
                                        }}
                                        noWrap
                                    >
                                        {item.title}
                                    </Typography>
                                </Box>
                            </ListItem>
                        );
                    })}
                </List>
                <Box sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', display: 'flex', marginY: 1 }}>
                    <Button
                        variant="outlined"
                        sx={{ marginX: 1, borderRadius: 6, textTransform: 'none' }}
                        onClick={handleExportCSV}
                        disabled={exportLoading}
                    >
                        {exportLoading ? <CircularProgress size={'1.2rem'} /> : ''} &nbsp; Export as CSV
                    </Button>
                </Box>
                <Dialog
                    sx={{ borderRadius: '12px', '& .MuiDialog-paper': { minWidth: '410px' } }}
                    open={openExportDialog}
                    onClose={handleExportDialogClose}
                >
                    <DialogTitle sx={{ padding: '20px', fontSize: '17px' }}>{exportDialog.title}</DialogTitle>
                    {exportDialog.message && <DialogContent sx={{ padding: '0px 20px 20px 20px' }}>{exportDialog.message}</DialogContent>}
                    <DialogActions sx={{ padding: '0px 20px 20px 20px' }}>
                        <Button variant="outlined" sx={{ borderRadius: 6 }} onClick={handleExportDialogClose}>
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </Card>
    );
};

export default TimeLineFilter;
