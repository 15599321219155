import React from 'react';
import { Box, Button, Typography, CardMedia, CardContent, Card } from '@mui/material';

const QuestionsTypeCard = ({ questionsTypes, item, handleClickOpen }) => {
    return (
        <Card
            sx={{
                display: 'flex',
                width: '100%',
                padding: '10px',
                m: 2,
                '&.MuiCard-root:hover': { boxShadow: '0 0 30px 0 rgb(0 0 0 / 15%)' },
                boxShadow: `${questionsTypes.type === item.questionType ? '0 0 30px 0 rgb(0 0 0 / 15%)' : ''}`
            }}
        >
            <CardMedia component="img" sx={{ width: 170, mr: 1 }} image={questionsTypes.image} alt={questionsTypes.title} />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h2">
                        {questionsTypes.title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ marginTop: '14px' }}>
                        {questionsTypes.description}
                    </Typography>

                    <Button
                        variant="contained"
                        onClick={() => handleClickOpen(questionsTypes.type)}
                        sx={{ borderRadius: '100px', width: '50%', textAlign: 'center', mt: 2 }}
                    >
                        <span> Select</span>
                    </Button>
                </CardContent>
            </Box>
        </Card>
    );
};

export default QuestionsTypeCard;
