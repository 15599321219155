import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';

const SubCard = forwardRef(
    ({ children, content, contentClass, darkTitle, secondary, sx = {}, contentSX = {}, title, handleClick, ...others }, ref) => {
        const theme = useTheme();
        const [editButtonShow, setEditButtonShow] = useState(false);
        const { isAllowToManageStandup } = useSelector((state) => state.standups);
        const { isAdmin } = useSelector((state) => state.login);

        return (
            <Box
                sx={{
                    position: 'relative',
                    ...sx
                }}
                onMouseEnter={(e) => {
                    setEditButtonShow(true);
                }}
                onMouseLeave={(e) => {
                    setEditButtonShow(false);
                }}
            >
                <Card
                    ref={ref}
                    sx={{
                        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
                        ':hover': {
                            boxShadow: `${isAllowToManageStandup || isAdmin ? '0 5px 14px 0 rgb(32 40 45 / 30%)' : ''}`
                        },
                        ...sx
                    }}
                    {...others}
                >
                    {!darkTitle && title && (
                        <CardHeader
                            sx={{ p: '10px 15px' }}
                            title={
                                <Typography variant="h5" sx={{ color: theme.palette.grey[700] }}>
                                    {title}
                                </Typography>
                            }
                            action={secondary}
                        />
                    )}
                    {darkTitle && title && (
                        <CardHeader
                            sx={{ p: '15px 15px' }}
                            title={
                                <Typography variant="h4" sx={{ color: theme.palette.grey[700] }}>
                                    {title}
                                </Typography>
                            }
                            action={secondary}
                        />
                    )}

                    {content && (
                        <CardContent
                            sx={{ p: '5px 15px', color: theme.palette.grey[700], fontSize: '1rem', ...contentSX }}
                            className={contentClass || ''}
                        >
                            {children}
                        </CardContent>
                    )}
                    {!content && children}
                </Card>
                {(isAllowToManageStandup || isAdmin) && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            onClick={handleClick}
                            startIcon={<EditIcon sx={{ fontSize: '5px' }} />}
                            sx={{
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '30px',
                                color: theme.palette.grey[500],
                                boxShadow: '0 5px 14px 0 rgb(32 40 45 / 10%)',
                                padding: '5px 10px',
                                position: 'absolute',
                                top: '-10px',
                                opacity: editButtonShow ? 1 : 0,
                                '&:hover': {
                                    backgroundColor: theme.palette.background.paper,
                                    boxShadow: '0 5px 14px 0 rgb(32 40 45 / 15%)'
                                },
                                fontSize: '12px'
                            }}
                        >
                            Click to Edit
                        </Button>
                    </Box>
                )}
            </Box>
        );
    }
);

SubCard.propTypes = {
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    sx: PropTypes.object,
    contentSX: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

SubCard.defaultProps = {
    content: true
};

export default SubCard;
