import { useEffect, useState } from 'react';
const InsightsPartcipationChartAnimation = ({ valueStart, valueEnd, children }) => {
    const [values, setValues] = useState(valueStart);
    useEffect(() => {
        setValues(valueEnd);
    }, [valueEnd]);

    return children(values);
};

export default InsightsPartcipationChartAnimation;
