import React from 'react';
import { Badge, Grid, Typography, Box, Stack, Link, Skeleton, CircularProgress } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import nodata from '../../../../../assets/images/users/nodata.jpg';
import Avatar from 'ui-component/extended/Avatar';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'ui-component/Loader';

import { seperateResponseAnswerString } from 'utils/share';
const TimeLineResponseData = ({ filterObject, setFilterObject }) => {
    const theme = useTheme();
    const { timeLineData, timeLineLoading, hasMore } = useSelector((state) => state.standups);
    const fetchTimeline = () => {
        setFilterObject({ ...filterObject, page: filterObject.page + 1 });
    };

    return (
        <Box>
            <InfiniteScroll dataLength={Object.entries(timeLineData).length} next={fetchTimeline} hasMore={hasMore}>
                {Object.entries(timeLineData).length ? (
                    <>
                        {Object.entries(timeLineData).map(([date, data]) => (
                            <Box key={date} sx={{ m: 2 }}>
                                <Badge
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}
                                    badgeContent={
                                        <Typography variant="h4">
                                            {date === moment().format('YYYY-MM-DD')
                                                ? 'Today'
                                                : date === moment().subtract(1, 'day').format('YYYY-MM-DD')
                                                ? 'Yesterday'
                                                : moment(date).format('dddd,MMMM Do')}
                                        </Typography>
                                    }
                                    sx={{
                                        width: '100%',
                                        border: '1px solid lightgray ',
                                        mb: 2,
                                        '& .MuiBadge-badge': {
                                            top: 1,
                                            left: '47%',
                                            background: 'white',
                                            BackgroundColor: '#fff',
                                            borderRadius: '12px',

                                            color: '#57585a',

                                            padding: '4px 8px',
                                            lineHeight: '1rem',
                                            position: 'absolute'
                                        }
                                    }}
                                ></Badge>
                                {data.map((itemData) => {
                                    return (
                                        filterObject?.Participants?.includes(itemData?.User?.slackUserId) && (
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        alignItems="center"
                                                        justifyContent="start"
                                                        sx={{
                                                            p: 3,
                                                            [theme.breakpoints.down('lg')]: {
                                                                padding: '24px 0px'
                                                            }
                                                        }}
                                                    >
                                                        <Avatar
                                                            src={itemData?.User?.image72}
                                                            sx={{
                                                                width: 56,
                                                                height: 56,
                                                                mr: 2,
                                                                bgcolor: theme.palette.grey[100]
                                                            }}
                                                        ></Avatar>
                                                        <Typography variant="h4" sx={{ mr: 1 }}>
                                                            {itemData?.User?.name}
                                                        </Typography>{' '}
                                                        <Typography variant={'div'}>
                                                            - {moment(itemData?.createdAt).format('h:mm a')}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        ml: 12,
                                                        [theme.breakpoints.down('lg')]: {
                                                            ml: 0
                                                        }
                                                    }}
                                                >
                                                    {itemData.answer.map((data) => {
                                                        return (
                                                            filterObject?.Questions?.includes(data?.questionId) && (
                                                                <Box
                                                                    key={data?.id}
                                                                    sx={{
                                                                        borderLeft: `4px solid ${data?.Question?.color}`,
                                                                        pl: 2,
                                                                        mb: 2
                                                                    }}
                                                                    display="flex"
                                                                    flexDirection="column"
                                                                    alignItems="start"
                                                                    justifyContent="start"
                                                                >
                                                                    <Stack spacing={2}>
                                                                        <Typography variant="h4" sx={{ mr: 1 }}>
                                                                            {data?.question}
                                                                        </Typography>
                                                                        {seperateResponseAnswerString(data?.answerText)}
                                                                    </Stack>
                                                                </Box>
                                                            )
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        )
                                    );
                                })}
                            </Box>
                        ))}
                    </>
                ) : timeLineLoading ? (
                    <>
                        <Loader />
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </div>
                    </>
                ) : (
                    <Stack justifyContent="center" alignItems="center" spacing={3}>
                        <img src={nodata} alt="nodata" width="30%" height="30%" />
                        <Typography variant="h1">No matches found</Typography>
                        <Typography variant="h5" alignItems="center" sx={{ textAlign: 'center' }}>
                            There's no data to display for your selected filters.
                            <br /> Please edit your selection, and Tixobot will look again.
                        </Typography>
                    </Stack>
                )}
            </InfiniteScroll>
        </Box>
    );
};

export default TimeLineResponseData;
