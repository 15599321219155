/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
    Autocomplete,
    Box,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Stack,
    TextField,
    Tooltip
} from '@mui/material';

import { IconPencil, IconHelp } from '@tabler/icons';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { getAllChannels } from '../channelSlice';
import useStyle from './useStyle';

const Basic = ({ isEditStandUp, standupDetail }) => {
    const theme = useTheme();
    const { values, handleChange, handleBlur, errors, touched, setFieldValue, submitForm } = useFormikContext();
    const { channels } = useSelector((state) => state.channels);
    const [open, setOpen] = React.useState(false);
    const loading = open && channels.length === 0;
    const dispatch = useDispatch();
    const { broadcastChannelSXProps, standupNameSXProps, formNameSXProps } = useStyle({ errors, touched });
    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }
        if (!channels.length) dispatch(getAllChannels());
    }, [loading]);

    useEffect(() => {
        if (isEditStandUp) {
            // let found = channel.filter((item) => standupDetail.broadcastChannels.includes(item.id));
        }
    }, [isEditStandUp]);

    const Pop = (props) => {
        const { className, anchorEl, style, ...rest } = props;
        const bound = anchorEl.getBoundingClientRect();
        return (
            <Stack
                {...rest}
                style={{
                    position: 'absolute',
                    zIndex: 9999,
                    width: bound.width
                }}
            />
        );
    };

    const CustomPaper = (props) => {
        return <Paper elevation={5} {...props} style={{ borderColor: 'red', borderRadius: `${theme?.customization?.borderRadius}px` }} />;
    };
    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center">
                <InputLabel sx={{ marginRight: 0.5 }}>Name</InputLabel>
                <Tooltip title="Enter a name that signifies the nature of the standup." placement="top">
                    <Stack>
                        <IconHelp stroke={1.5} size="1rem" color={theme.palette.grey[500]} />{' '}
                    </Stack>
                </Tooltip>
            </Box>
            <OutlinedInput
                name="name"
                id="outlined-adornment-name"
                sx={standupNameSXProps}
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Ex. Daily StandUp"
                error={touched.name && Boolean(errors.name)}
                startAdornment={
                    <InputAdornment position="start">
                        <IconPencil stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                    </InputAdornment>
                }
                inputProps={{}}
            />
            {touched.name && errors.name && (
                <FormHelperText error id="standard-weight-helper-text-name">
                    <Stack sx={formNameSXProps}> {errors.name}</Stack>
                </FormHelperText>
            )}
            <Box display="flex" flexDirection="row" alignItems="center">
                <InputLabel sx={{ marginRight: 0.5 }}>Broadcast channel</InputLabel>
                <Tooltip
                    title="Public or private Slack channels where TixoBot will post everyone’s reports. You can choose a private channel by typing its name and selecting it"
                    placement="top"
                >
                    <Stack>
                        <IconHelp stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                    </Stack>
                </Tooltip>
            </Box>
            <Autocomplete
                multiple
                name="channel"
                loading={loading}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onBlur={handleBlur}
                ListboxProps={{ style: { maxHeight: '15rem' } }}
                PopperComponent={loading && Pop}
                PaperComponent={CustomPaper}
                id="combo-box"
                options={channels}
                value={values?.broadcastChannels}
                onChange={(event, value) => {
                    return setFieldValue('broadcastChannels', value);
                }}
                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                sx={{ width: '100%', my: 2 }}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name="broadcastChannels"
                        error={touched.broadcastChannels && Boolean(errors.broadcastChannels)}
                        helperText={touched.broadcastChannels && Boolean(errors.broadcastChannels) && errors.broadcastChannels}
                        placeholder="Slack Broadcast channels...."
                        sx={broadcastChannelSXProps}
                    />
                )}
            />
        </>
    );
};

export default Basic;
