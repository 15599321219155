import React from 'react';
import { Box, Badge, List, ListItem, ListItemAvatar, Typography, ListItemText } from '@mui/material';
import Avatar from 'ui-component/extended/Avatar';

import logo from 'assets/images/logo.png';
import { livePreviewMessageHandler } from 'utils/share';

const LivePreviewBox = ({ message, time, standup, userName }) => {
    let livePreviewMessage = livePreviewMessageHandler(message, standup, userName);

    return (
        <Box display="flex" flexDirection="row" alignItems="center" sx={{ width: '100%' }}>
            <Badge
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                badgeContent={'Live Preview'}
                sx={{
                    width: '100%',

                    '& .MuiBadge-badge': {
                        top: 1,
                        left: 55,
                        background: 'white',
                        BackgroundColor: '#fff',
                        borderRadius: '12px',
                        boxShadow: '0 0 8px 0 rgb(0 0 0 / 16%)',
                        color: '#57585a',

                        padding: '4px 8px',
                        lineHeight: '1rem',
                        position: 'absolute'
                    }
                }}
            >
                <List sx={{ width: 'inherit', bgcolor: '#fafafa', borderRadius: '8px' }}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={logo} sx={{ borderRadius: 1 }} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                        Tixobot
                                    </Typography>{' '}
                                    <Typography
                                        sx={{
                                            display: 'inline',
                                            background: '#ddd',
                                            p: '3px 5px',
                                            fontSize: '10px',
                                            borderRadius: '2px',
                                            m: '3px'
                                        }}
                                        component="span"
                                        variant="h6"
                                        color="text.primary"
                                    >
                                        App
                                    </Typography>{' '}
                                    <Typography
                                        sx={{
                                            display: 'inline',
                                            fontSize: '10px'
                                        }}
                                        component="span"
                                        variant="h6"
                                        color="text.primary"
                                    >
                                        {time}
                                    </Typography>
                                </React.Fragment>
                            }
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline', wordWrap: 'break-word' }}
                                        component="span"
                                        variant="body2"
                                        color="black"
                                    >
                                        {livePreviewMessage}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </List>
            </Badge>
        </Box>
    );
};

export default LivePreviewBox;
