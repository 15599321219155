import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import plainTextType from '../../../../../../assets/images/questionsTypes/plain-text.svg';
import numericType from '../../../../../../assets/images/questionsTypes/numeric.svg';
import preDefineType from '../../../../../../assets/images/questionsTypes/pre-define.svg';

import QuestionsTypeCard from './QuestionsTypeCard';
import PreDefineResponsDialog from './PreDefineResponsDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const questionsTypes = [
    {
        title: 'Plain text',
        type: 'plain-text',
        description: 'free text field',
        image: plainTextType
    },
    {
        title: 'Numeric',
        type: 'numeric',
        description: 'accepts only numeric value',
        image: numericType
    },
    {
        title: 'Pre-defined',
        type: 'pre-define',
        description: 'pre-defined answers to select from',
        image: preDefineType
    }
];
const QuestionsTypeModal = ({ handleClose, open, item, onQuestionTypeChange, index }) => {
    const [openPreDefineDialog, setOpenPreDefineDialog] = React.useState(false);

    const handleClickPreDefineDialogOpen = (e) => {
        e === 'pre-define' && setOpenPreDefineDialog(true);

        if (e === 'plain-text' || e === 'numeric') {
            onQuestionTypeChange(e, index);
            handleClose();
        }
    };

    const handleClosePreDefineResponse = () => {
        setOpenPreDefineDialog(false);
    };
    const handleCloseBothDialog = () => {
        setOpenPreDefineDialog(false);
        handleClose();
    };
    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <Box sx={{ margin: '45px 45px 10px 45px', width: '95%' }}>
                <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <IconButton
                        component="button"
                        size="large"
                        sx={{
                            border: ' 2px solid #b1b2b3',
                            borderRadius: '100%',

                            textAlign: 'center',
                            height: '48px',
                            width: '48px',
                            color: '#b1b2b3'
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Stack>
            </Box>
            <Box sx={{ margin: '10px auto', width: '50%' }}>
                <Stack spacing={4} alignItems="center">
                    <Stack>
                        <Typography variant="h1" sx={{ marginBottom: '12px', textAlign: 'center', fontWeight: '900' }}>
                            Response types
                        </Typography>
                        <Typography variant="h5">Select the way participants respond to questions</Typography>
                    </Stack>

                    <Stack direction="column">
                        {questionsTypes.map((questionType, index) => (
                            <QuestionsTypeCard
                                handleClose={handleClose}
                                questionsTypes={questionType}
                                item={item}
                                key={index}
                                handleClickOpen={handleClickPreDefineDialogOpen}
                            />
                        ))}
                    </Stack>
                </Stack>
            </Box>
            <PreDefineResponsDialog
                onQuestionTypeChange={onQuestionTypeChange}
                handleClosePreDefineResponse={handleClosePreDefineResponse}
                handleCloseBothDialog={handleCloseBothDialog}
                open={openPreDefineDialog}
                Transition={Transition}
                itemIndex={index}
                item={item}
            />
        </Dialog>
    );
};
export default QuestionsTypeModal;
