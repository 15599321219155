import { lazy, useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router';
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const AddStandUp = Loadable(lazy(() => import('views/pages/AddStandUp/StandUps')));
const ListAllStandUps = Loadable(lazy(() => import('views/pages/ListStandUps/ListStandUps')));
const Participation = Loadable(lazy(() => import('views/pages/AddStandUp/participants/Participation')));
const AdminDashboard = Loadable(lazy(() => import('views/pages/AdminDashboard/Admin/AdminDashboard')));
import MinimalLayout from 'layout/MinimalLayout';
import Basic from 'views/pages/AddStandUp/basic/Basic';
import Questions from 'views/pages/AddStandUp/questions/components/Questions';
import Schedule from 'views/pages/AddStandUp/schedule/Schedule';
import AuthLogin from 'views/login/Login';
import Advanced from 'views/pages/AddStandUp/Advanced/Advanced';
import StandUpDetail from 'views/pages/StandUpDetail/StandUpDetail';
import StandUps from 'views/pages/AddStandUp/StandUps';
import ManageStandUps from 'views/pages/manageStandups/ManageStandUps';

export default function ThemeRoutes() {
    const { user, isAdmin } = useSelector((state) => state.login);

    const { isAllowToManageStandup } = useSelector((state) => state.standups);
    return (
        <Routes>
            <Route path="/" element={user ? <MainLayout /> : <Navigate to="/auth/login" />}>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="admin/dashboard" element={isAdmin && <AdminDashboard />} />

                <Route path="standups" element={<ListAllStandUps />} />
                <Route path="standup/create" element={<AddStandUp />} />
                <Route path="standup/create/participation" element={<Participation />} />
                <Route path="standup/create/basic" element={<Basic />} />
                <Route path="standup/create/questions" element={<Questions />} />
                <Route path="standup/create/schedule" element={<Schedule />} />
                <Route path="standup/create/Advanced" element={<Advanced />} />
                <Route path="/dashboard/standup/:id/view" element={<StandUpDetail />} />
                <Route
                    path="/dashboard/standup/manage/:id/:path"
                    element={isAllowToManageStandup || isAdmin ? <StandUps /> : <ManageStandUps />}
                />
                <Route path="*" element={<Navigate to="/auth/login" />} />
            </Route>
            <Route path="/auth" element={<MinimalLayout />}>
                <Route path="login" element={<AuthLogin />} />
                <Route path="*" element={<Navigate to="/auth/login" />} />
            </Route>
            <Route path="*" element={<Navigate to="/auth/login" />} />
        </Routes>
    );
}
