import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Tabs, Tab, Stack, CircularProgress, Alert, AlertTitle } from '@mui/material';
import { Formik } from 'formik';
import Basic from './basic/Basic';
import Questions from './questions/components/Questions';
import Schedule from './schedule/Schedule';
import MainCard from 'ui-component/cards/MainCard';
import Participation from './participants/Participation';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { daysList, defaultIntroMessage, defaultOutroMessage, draggableField } from 'utils/common';
import { validationSchema } from 'validations/standupsValidation';
import useStandUp from './useStandUp';
import TabPanel from './tabcontainer/TabPanel';
import { convertTimeFormatTo24Hour, getCapitalize, lastReportedAtHandler } from 'utils/share';
import { useDispatch } from 'react-redux';
import { createStandup, getStandUpDetailData, updateStandUp } from './standupSlice';
import { getStandUpDetail } from 'services/standup.services';
import Advanced from './Advanced/Advanced';
import Loader from 'ui-component/Loader';
import { useSelector } from 'react-redux';

import StandUpDetail from '../StandUpDetail/StandUpDetail';
import { toast } from 'react-toastify';

const StandUps = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSubmitting, setSubmitting] = useState(false);
    const { handleTabChange, tabProps, tabValue, setTabValue, theme, isEditStandUp, standupDetail, formikRef, loading } = useStandUp();

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                name: '',

                broadcastChannels: [],

                questionsList: [],
                introMessage: '',
                outroMessage: '',
                participants: [],

                isLocalTimeZone: false,

                isSyncToChannel: false,
                days: daysList[0],
                scheduleDays: [],
                schedular_type: '',
                scheduleTime: '',
                scheduleDate: '',
                timeZone: 'Asia/Calcutta',
                time: ''
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setErrors, setStatus, status }) => {
                setSubmitting(true);

                let defaultIntroMsg = values.introMessage.length ? values.introMessage : defaultIntroMessage;
                let defaultOutroMsg = values.outroMessage ? values.outroMessage : defaultOutroMessage;
                let channel = values.broadcastChannels.map((channel) => channel.id);

                let questions = values.questionsList.map((question, i) => ({
                    color: question.color,
                    title: question?.title,
                    order: ++i,
                    ...(isEditStandUp && !isNaN(question.id) && { id: question.id })
                }));

                let participants = values.participants.map((user) => user.permissions);
                const payload = {
                    ...values,
                    questionsList: questions,
                    broadcastChannels: channel,
                    introMessage: defaultIntroMsg,
                    outroMessage: defaultOutroMsg,
                    timeZone: values.isLocalTimeZone ? '' : values.timeZone,
                    participants,
                    time: convertTimeFormatTo24Hour(values?.time)
                };
                delete payload.days;
                delete payload.syncToChannel;
                delete payload.broadcastChannel;
                delete payload.scheduleDate;
                if (payload.schedular_type === 'onetime') {
                    delete payload.scheduleDays;
                } else {
                    delete payload.scheduleTime;
                }
                if (isEditStandUp) {
                    dispatch(updateStandUp({ ...payload, standupId: standupDetail.id }))
                        .then((res) => {
                            if (res.error) {
                                toast.error('Something went wrong!');
                            } else {
                                toast.success('Updated successfully!');
                                navigate('/dashboard');
                            }
                            setSubmitting(false);
                        })
                        .catch((err) => {
                            toast.error('Something went wrong!');
                            console.log(err);
                            setSubmitting(false);
                        });
                } else {
                    dispatch(createStandup(payload))
                        .then((res) => {
                            if (res.error) {
                                toast.error('Something went wrong!');
                            } else {
                                toast.success('Created successfully!');
                                navigate('/dashboard');
                            }
                            setSubmitting(false);
                        })
                        .catch((err) => {
                            toast.error('Something went wrong!');
                            console.log(err);
                            setSubmitting(false);
                        });
                }

                setStatus({ success: true });
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue, selectedDays, status }) => (
                <MainCard
                    darkTitle
                    title={isEditStandUp ? getCapitalize(standupDetail?.name) : 'Create Standup'}
                    border={false}
                    contentSX={{ display: 'flex', justifyContent: 'center' }}
                    secondary={
                        <Box display="flex" justifyContent="space-between" alignItems="flex-end" width={'100%'}>
                            <Button
                                variant="contained"
                                disableElevation
                                size="large"
                                type="submit"
                                sx={{ margin: '10px 0px ' }}
                                disabled={
                                    isSubmitting ||
                                    !values?.name ||
                                    Boolean(errors.name) ||
                                    Boolean(errors.questionsList) ||
                                    Boolean(errors.introMessage) ||
                                    Boolean(errors.outroMessage) ||
                                    !values?.broadcastChannels?.length ||
                                    !values?.questionsList?.length ||
                                    !values?.participants?.length ||
                                    !values?.questionsList?.every((item) => item?.title?.length)
                                }
                                onClick={handleSubmit}
                            >
                                {isEditStandUp ? (isSubmitting ? 'Updating...' : 'Update') : isSubmitting ? 'Creating...' : 'Save'}
                            </Button>
                        </Box>
                    }
                >
                    <Box
                        sx={{
                            width: '70%',

                            [theme.breakpoints.down('lg')]: {
                                width: `100%`
                            }
                        }}
                    >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleTabChange}>
                                <Tab
                                    label="Basic"
                                    disabled={Boolean(errors.questionsList) || Boolean(errors.introMessage) || Boolean(errors.outroMessage)}
                                    sx={{ fontWeight: '500', fontSize: '18px' }}
                                    {...tabProps(0)}
                                />
                                <Tab
                                    label="Questions"
                                    sx={{ fontWeight: '500', fontSize: '18px' }}
                                    disabled={
                                        !values?.name || errors.name || errors.broadcastChannels || !values?.broadcastChannels?.length
                                    }
                                    {...tabProps(1)}
                                />
                                <Tab
                                    label="Participants"
                                    sx={{ fontWeight: '500', fontSize: '18px' }}
                                    disabled={
                                        !values?.name ||
                                        Boolean(errors.questionsList) ||
                                        Boolean(errors.introMessage) ||
                                        Boolean(errors.outroMessage) ||
                                        !values?.questionsList?.length ||
                                        !values?.broadcastChannels?.length
                                    }
                                    {...tabProps(2)}
                                />
                                <Tab
                                    label="Schedule"
                                    sx={{ fontWeight: '500', fontSize: '18px' }}
                                    disabled={
                                        !values?.name ||
                                        Boolean(errors.questionsList) ||
                                        Boolean(errors.introMessage) ||
                                        Boolean(errors.outroMessage) ||
                                        !values?.participants?.length ||
                                        !values?.broadcastChannels?.length
                                    }
                                    {...tabProps(3)}
                                />
                                {isEditStandUp && (
                                    <Tab
                                        label="Advanced"
                                        sx={{ fontWeight: '500', fontSize: '18px' }}
                                        disabled={
                                            !values?.name ||
                                            Boolean(errors.questionsList) ||
                                            Boolean(errors.introMessage) ||
                                            Boolean(errors.outroMessage) ||
                                            !values?.broadcastChannels?.length
                                        }
                                        {...tabProps(4)}
                                    />
                                )}
                            </Tabs>
                        </Box>

                        <TabPanel value={tabValue} index={0}>
                            <Basic isEditStandUp={isEditStandUp} standupDetail={standupDetail} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Questions isEditStandUp={isEditStandUp} standupDetail={standupDetail} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <Participation isEditStandUp={isEditStandUp} standupDetail={standupDetail} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <Schedule isEditStandUp={isEditStandUp} standupDetail={standupDetail} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={4} disabled={!values.name || values.channel?.length === 0}>
                            <Advanced data={standupDetail} />
                        </TabPanel>
                        {!tabValue === '3' && (
                            <Box display="flex" justifyContent="flex-end" alignItems="flex-end" width={'100%'}>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    size="large"
                                    type="submit"
                                    sx={{ margin: '10px 0px ' }}
                                    disabled={
                                        !values?.name ||
                                        !values?.broadcastChannels?.length ||
                                        !values?.questionsList?.every((item) => item?.title?.length)
                                    }
                                    onClick={() => setTabValue((prevTab) => prevTab + 1)}
                                >
                                    Next
                                </Button>
                            </Box>
                        )}
                    </Box>
                </MainCard>
            )}
        </Formik>
    );
};

export default StandUps;
