import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login } from 'services/login.service';
import {
    createNewStandUp,
    getAllStandUps,
    UpdateStandUp,
    getStandUpDetail,
    deleteStandUp,
    standUpsTimeLine,
    UpdateStandUpStatus
} from 'services/standup.services';

const initialState = {
    standUps: [],
    standupDetail: {},
    timeLineData: {},
    isAllowToManageStandup: false,
    error: '',
    loading: false,
    timeLineLoading: false,
    hasMore: false
};

export const createStandup = createAsyncThunk('standUp/createStandup', async (payload) => {
    const response = await createNewStandUp(payload);
    return response.data;
});

export const standUpTimeLine = createAsyncThunk('standUp/standUpTimeLine', async (payload) => {
    const response = await standUpsTimeLine(payload);
    return response.data;
});
export const getAllStandUp = createAsyncThunk('standUp/allStandups', async () => {
    const response = await getAllStandUps();

    return response.data;
});

export const updateStandUp = createAsyncThunk('standUp/updateStandup', async (payload) => {
    const response = await UpdateStandUp(payload);
    return response.data;
});
export const updateStandUpStatus = createAsyncThunk('standUp/updateStandUpStatus', async (payload) => {
    const response = await UpdateStandUpStatus(payload);
    return response.data;
});
export const getStandUpDetailData = createAsyncThunk('standUp/getStandUpDetailData', async (payload) => {
    const response = await getStandUpDetail(payload);
    return response.data;
});
export const deleteStandup = createAsyncThunk('standUp/deleteStandup', async (payload) => {
    const response = await deleteStandUp(payload);
    return response.data;
});

export const standUpSlice = createSlice({
    name: 'standUp',
    initialState,

    reducers: {
        resetTimeline: (state) => {
            state.timeLineData = {};
        },
        setManageStandupPermission: (state, action) => {
            state.isAllowToManageStandup = action.payload;
        }
    },

    extraReducers: {
        [getAllStandUp.pending]: (state) => {
            state.loading = true;
        },

        [getAllStandUp.fulfilled]: (state, action) => {
            state.standUps = action.payload.data;

            state.loading = false;
        },

        [getAllStandUp.rejected]: (state, { error }) => {
            state.error = error;
            state.loading = false;
        },
        [createStandup.pending]: (state) => {
            state.loading = true;
        },
        [createStandup.fulfilled]: (state, { payload }) => {
            state.standUps.push(payload);

            state.loading = false;
        },

        [createStandup.rejected]: (state, action) => {
            state.error = action.error;

            state.loading = false;
        },
        [updateStandUp.pending]: (state) => {
            state.loading = true;
        },
        [updateStandUp.fulfilled]: (state, { payload }) => {
            // state.standUps = payload;

            state.loading = false;
        },
        [updateStandUp.rejected]: (state, { error }) => {
            state.error = error;
            state.loading = false;
        },
        [getStandUpDetailData.pending]: (state) => {
            state.loading = true;
        },
        [getStandUpDetailData.fulfilled]: (state, { payload }) => {
            state.standupDetail = payload.data;

            state.loading = false;
        },

        [getStandUpDetailData.rejected]: (state, { error }) => {
            state.standupDetail = initialState.standupDetail;
            state.error = error;
            state.loading = false;
        },
        [deleteStandup.pending]: (state) => {
            state.loading = true;
        },
        [deleteStandup.fulfilled]: (state, { payload }) => {
            // state.standupDetail = payload.data;

            state.loading = false;
        },

        [deleteStandup.rejected]: (state, { error }) => {
            state.error = error;
            state.loading = false;
        },
        [updateStandUpStatus.pending]: (state) => {
            state.loading = true;
        },
        [updateStandUpStatus.fulfilled]: (state, { payload }) => {
            state.loading = false;
        },

        [updateStandUpStatus.rejected]: (state, { error }) => {
            state.error = error;
            state.loading = false;
        },
        [standUpTimeLine.pending]: (state) => {
            state.timeLineLoading = true;
        },
        [standUpTimeLine.fulfilled]: (state, { payload }) => {
            state.timeLineData = { ...state.timeLineData, ...payload?.data };
            if (!Object.entries(payload.data).length) {
                state.hasMore = false;
            } else {
                state.hasMore = true;
            }
            state.timeLineLoading = false;
        },

        [standUpTimeLine.rejected]: (state, { error }) => {
            state.error = error;
            state.timeLineLoading = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { resetTimeline, setManageStandupPermission } = standUpSlice.actions;

export default standUpSlice.reducer;
