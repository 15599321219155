import { Avatar, AvatarGroup, Chip, CircularProgress, Grid, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardHeader, IconButton, ListItem } from '@mui/material';
import { makeStyles, useTheme, useThemeVariants } from '@mui/styles';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getStandUpDetail, standUpsTimeLine } from 'services/standup.services';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Loader from 'ui-component/Loader';
import { useNavigate } from 'react-router-dom';
import { getCapitalize } from 'utils/share';
import { useSelector, useDispatch } from 'react-redux';
import { getStandUpDetailData, setManageStandupPermission } from '../AddStandUp/standupSlice';
import TabPanel from '../AddStandUp/tabcontainer/TabPanel';
import Basic from '../AddStandUp/basic/Basic';
import Questions from '../AddStandUp/questions/components/Questions';
import TimeLine from '../TimeLine/components/TimeLine';
import Insights from '../Insights/Insights';
import Schedule from './Schedule/Schedule';

const StandUpDetail = () => {
    let { id } = useParams();
    const [ChannelsList, setChannelsList] = useState([]);
    const [scheduleData, setScheduleData] = useState({});
    const [tabValue, setTabValue] = React.useState(0);
    const [participants, setParticipants] = useState([]);
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, isAdmin } = useSelector((state) => state.login);
    const { standupDetail, loading, isAllowToManageStandup } = useSelector((state) => state.standups);

    const { isMultipleDaysTimeline } = useSelector((state) => state.insights);
    const { channels } = useSelector((state) => state.channels);
    const [currentUserPermission, setCurrentUserPermission] = useState(false);

    let currentUserId = user.slackUserId;
    useEffect(() => {
        isMultipleDaysTimeline?.isTimelineClick && setTabValue(1);
    }, [isMultipleDaysTimeline]);
    useEffect(() => {
        let currentUserPermissions = standupDetail?.Participants?.filter((participants) =>
            participants?.User?.slackUserId?.includes(currentUserId)
        )[0]?.isManage;
        setCurrentUserPermission(currentUserPermissions);
    }, [standupDetail]);
    useEffect(() => {
        dispatch(setManageStandupPermission(currentUserPermission));
    }, [currentUserPermission]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function tabProps(index) {
        return {
            id: `create-standup-${index}`,
            'aria-controls': `create-standup-panel-${index}`
        };
    }
    useEffect(() => {
        if (id) {
            dispatch(getStandUpDetailData(id));
        }
    }, [id]);

    useEffect(() => {
        if (standupDetail?.Participants) {
            const participantsList = standupDetail?.Participants?.filter((item) => item?.isReport);
            setParticipants(participantsList);
        }
        if (standupDetail?.scheduleDays && standupDetail.time && standupDetail.schedular_type) {
            setScheduleData({
                dayList: standupDetail.scheduleDays,
                time: standupDetail.time,
                timeZone: standupDetail.timeZone,
                scheduleType: standupDetail.schedular_type,
                scheduleTime: standupDetail.scheduleTime
            });

            if (channels.length) {
                const found = channels.filter((item) => standupDetail.broadcastChannels.includes(item.id));
                setChannelsList(found);
            }
        }
    }, [standupDetail, channels]);

    const handleClick = (path) => {
        if (isAllowToManageStandup || isAdmin) navigate(`/dashboard/standup/manage/${id}/${path}`);
    };
    return loading ? (
        <>
            <Loader />
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
            </div>
        </>
    ) : standupDetail ? (
        <>
            <MainCard
                darkTitle
                title={getCapitalize(standupDetail?.name)}
                border={false}
                contentSX={{ display: 'flex', justifyContent: 'center' }}
                secondary={<Box display="flex" justifyContent="flex-end" alignItems="flex-end" width={'100%'}></Box>}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SubCard title="Schedule" darkTitle handleClick={() => handleClick('schedule')}>
                            <Schedule scheduleData={scheduleData} />
                        </SubCard>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <SubCard title="Questions" sx={{ height: '100%' }} darkTitle handleClick={() => handleClick('questions')}>
                            {standupDetail?.Questions?.map((item, index) => {
                                return (
                                    <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }} key={index}>
                                        <FiberManualRecordIcon fontSize="small" sx={{ fontSize: 15, color: item.color, marginRight: 2 }} />
                                        <div>{item.title}</div>
                                    </Box>
                                );
                            })}
                        </SubCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2} direction="column">
                            <Grid item xs={8} direction="row">
                                <SubCard title="Participants" darkTitle handleClick={() => handleClick('participants')}>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <AvatarGroup max={8} spacing={'medium'}>
                                            {participants?.length &&
                                                participants?.map((item, index) => {
                                                    return (
                                                        <Tooltip title={`@${item?.User?.name}`} arrow key={index} placement="top">
                                                            <Avatar
                                                                src={item?.User?.image48 || '/broken-image.jpg'}
                                                                sx={{
                                                                    width: 56,
                                                                    height: 56,
                                                                    bgcolor: theme.palette.grey[100]
                                                                }}
                                                            ></Avatar>
                                                        </Tooltip>
                                                    );
                                                })}
                                        </AvatarGroup>
                                    </Stack>
                                </SubCard>
                            </Grid>
                            <Grid item xs={4}>
                                <SubCard darkTitle title="Broadcast Channels" handleClick={() => handleClick('basic')}>
                                    {ChannelsList &&
                                        ChannelsList.map((item) => {
                                            return (
                                                <Chip
                                                    key={item.id}
                                                    label={`#${item.name}`}
                                                    variant="outlined"
                                                    sx={{ margin: 0.5, color: theme.palette.grey[800], fontWeight: 500 }}
                                                />
                                            );
                                        })}
                                </SubCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
            <MainCard border={false} contentSX={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Box
                    sx={{
                        width: '100%',

                        [theme.breakpoints.down('lg')]: {
                            width: `100%`
                        }
                    }}
                >
                    <Box sx={{ borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="Insights" sx={{ fontWeight: '500', fontSize: '18px' }} {...tabProps(0)} />
                            <Tab
                                label="
                    Timeline"
                                sx={{ fontWeight: '500', fontSize: '18px' }}
                                {...tabProps(1)}
                            />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <Insights standupDetail={standupDetail} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1} sx={{ width: '100%' }}>
                        <TimeLine />
                    </TabPanel>
                </Box>
            </MainCard>
        </>
    ) : (
        <div style={{ textAlign: 'center' }}>No data Found</div>
    );
};

export default StandUpDetail;
