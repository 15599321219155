import { useTheme } from '@mui/material/styles';
const useStyle = () => {
    const theme = useTheme();
    return {
        manageStandupWrapper: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'start',
            width: '100%',
            height: '100%'
        },
        manageStandupMessageWrapper: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50%'
        }
    };
};

export default useStyle;
