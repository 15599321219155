import axios from 'axios';
import { useSelector } from 'react-redux';
import { API_BASE_URL, SLACK_API_BASE_URL } from '../utils/constant';

import { getUser } from 'utils/share';
export const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    crossDomain: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const axiosInstanceSlack = axios.create({
    baseURL: SLACK_API_BASE_URL,
    crossDomain: true
});

// Add a request interceptor
axiosInstance.interceptors.request.use(async function (config) {
    const authToken = getUser().token;
    try {
        const jwtToken = authToken ? authToken : '';

        const token = `Bearer ${jwtToken}`;
        config.headers.Authorization = token;
        return config;
    } catch (error) {}
});

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.data && response.data.error) {
            return Promise.reject(response);
        }
        return response;
    },
    (error) => {
        if (!error.response) {
        }
        if (error.response?.status === 440 || error.response?.status === 401) {
        }
        return Promise.reject(error);
    }
);
