import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login } from 'services/login.service';

const initialState = {
    token: undefined,
    user: undefined,
    error: '',
    isAdmin: false,
    loading: false
};

export const loginUserThunk = createAsyncThunk('login/loginUser', async (payload) => {
    const response = await login(payload);
    return response.data;
});
export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLogin: (state, action) => {
            state.token = action?.payload?.data.token;
            state.user = action?.payload?.data.user;
        },
        setLogout: (state) => {
            state.token = undefined;
            state.user = undefined;
        },
        setIsAdmin: (state, { payload }) => {
            state.isAdmin = payload;
        }
    },
    extraReducers: {
        [loginUserThunk.pending]: (state) => {
            state.loading = true;
        },
        [loginUserThunk.fulfilled]: (state, { payload }) => {
            state.token = payload?.data.token;
            state.user = payload?.data.user;
            state.isAdmin = payload?.data?.user?.isAdmin;
            state.loading = false;
        },

        [loginUserThunk.rejected]: (state, { error }) => {
            state.error = error;
            state.loading = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setLogin, setIsAdmin, setLogout } = loginSlice.actions;

export default loginSlice.reducer;
