import { useEffect, useState, useTransition } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery, Box, Button, CircularProgress } from '@mui/material';
import Logo from 'ui-component/Logo';
import AnimateButton from 'ui-component/extended/AnimateButton';
import Slack from 'assets/images/icons/slack.svg';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { SLACK_CLIENT_ID } from 'utils/constant';
import { styled } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import { Login } from 'services/login.service';
import Loader from 'ui-component/Loader';
import { useDispatch } from 'react-redux';

import { loginUserThunk } from './loginSlice';

const AuthCardWrapper = ({ children, ...other }) => (
    <MainCard
        sx={{
            maxWidth: { xs: 400, lg: 475 },
            margin: { xs: 2.5, md: 3 },
            '& > *': {
                flexGrow: 1,
                flexBasis: '50%'
            }
        }}
        content={false}
        {...other}
    >
        <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
    </MainCard>
);

const AuthWrapper1 = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    minHeight: '100vh'
}));

const LoginPage = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [searchParams] = useSearchParams();
    const [isLoading, setLoading] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const authCode = searchParams?.get('code') ?? '';
        if (authCode !== '') {
            setLoading(true);

            const redirectionUrl = encodeURIComponent(window?.location?.origin + window?.location?.pathname);

            dispatch(loginUserThunk({ authCode, redirectionUrl }))
                .then((response) => {
                    navigate('/dashboard');
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const handleClick = () => {
        const redirectionUrl = encodeURIComponent(window?.location?.origin + window?.location?.pathname);
        const slackOauthUrl = `https://slack.com/openid/connect/authorize?scope=openid&response_type=code&redirect_uri=${redirectionUrl}&client_id=${SLACK_CLIENT_ID}`;
        window.location.replace(slackOauthUrl);
    };

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 1 }}>
                                        <Logo />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        Hi, Welcome Back
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="column" justifyContent="center" spacing={2}>
                                            <Grid item xs={12}>
                                                {isLoading ? (
                                                    <>
                                                        <Loader />
                                                        <div style={{ textAlign: 'center' }}>
                                                            <CircularProgress />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            fullWidth
                                                            onClick={handleClick}
                                                            size="large"
                                                            variant="outlined"
                                                            sx={{
                                                                color: 'grey.700',
                                                                backgroundColor: theme.palette.grey[50],
                                                                borderColor: theme.palette.grey[100]
                                                            }}
                                                        >
                                                            <Box sx={{ mr: { xs: 1, sm: 2, width: 20 } }}>
                                                                <img
                                                                    src={Slack}
                                                                    alt="slack"
                                                                    width={25}
                                                                    height={25}
                                                                    style={{ marginRight: matchDownSM ? 8 : 16, marginTop: 5 }}
                                                                />
                                                            </Box>
                                                            Sign in with slack
                                                        </Button>
                                                    </AnimateButton>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default LoginPage;
