import { Button, IconButton, Paper, Slide, Stack, Typography } from '@mui/material';
import PopupState, { bindMenu, bindHover } from 'material-ui-popup-state';
import React from 'react';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PinIcon from '@mui/icons-material/Pin';
import QuestionsTypeModal from './QuestionsTypeDialog';
import PreDefineResponsDialog from './PreDefineResponsDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SelectedTypePopover = ({ icon, list, item, index, onDeleteQuestionHandler, onColorChange, onQuestionTypeChange }) => {
    const [open, setOpen] = React.useState(false);
    const [openPreDefineDialog, setOpenPreDefineDialog] = React.useState(false);
    const handleClickPreDefineDialogOpen = (e) => {
        setOpenPreDefineDialog(true);
    };

    const handleClosePreDefineResponse = () => {
        setOpenPreDefineDialog(false);
    };
    const handleCloseBothDialog = () => {
        setOpenPreDefineDialog(false);
        handleClose();
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <>
                    <IconButton id="questionPopover" variant="contained" {...bindHover(popupState)} sx={{ p: '10px' }} aria-label="search">
                        {item.questionType === 'numeric' ? (
                            <PinIcon />
                        ) : item.questionType === 'pre-define' ? (
                            <FormatListNumberedIcon />
                        ) : null}
                    </IconButton>

                    <HoverMenu {...bindMenu(popupState)}>
                        <Paper sx={{ textAlign: 'center' }}>
                            {item.questionType === 'numeric' ? (
                                <Stack sx={{ width: '100%', maxWidth: 180, p: 1, bgcolor: 'background.paper' }}>
                                    <Typography variant="caption" display="block" sx={{ fontWeight: 300 }}>
                                        This question accepts only numeric responses.
                                    </Typography>
                                    <Button onClick={handleClickOpen} sx={{ fontWeight: 300 }}>
                                        Click to Edit
                                    </Button>
                                </Stack>
                            ) : item.questionType === 'pre-define' ? (
                                <Stack sx={{ width: '100%', maxWidth: 180, p: 1, bgcolor: 'background.paper' }}>
                                    <Typography variant="caption" display="block" sx={{ fontWeight: 300 }}>
                                        This question accepts only predefined responses
                                        <Button onClick={handleClickPreDefineDialogOpen} sx={{ fontWeight: 300 }}>
                                            Click to Edit
                                        </Button>
                                    </Typography>
                                </Stack>
                            ) : null}
                        </Paper>
                    </HoverMenu>
                    <QuestionsTypeModal
                        index={index}
                        handleClose={handleClose}
                        open={open}
                        item={item}
                        onQuestionTypeChange={onQuestionTypeChange}
                    />
                    <PreDefineResponsDialog
                        onQuestionTypeChange={onQuestionTypeChange}
                        handleClosePreDefineResponse={handleClosePreDefineResponse}
                        handleCloseBothDialog={handleCloseBothDialog}
                        open={openPreDefineDialog}
                        Transition={Transition}
                        itemIndex={index}
                        item={item}
                    />
                </>
            )}
        </PopupState>
    );
};

export default SelectedTypePopover;
