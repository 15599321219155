import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    Stack,
    CircularProgress,
    FormControlLabel,
    Grid,
    Popover,
    TextField,
    Typography,
    IconButton,
    Skeleton,
    Link,
    useTheme
} from '@mui/material';
import Avatar from 'ui-component/extended/Avatar';
import moment from 'moment';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useDispatch, useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import { seperateResponseAnswerString } from 'utils/share';
import SkeletonInsightsForOneDay from '../InsightsForOneDay/SkeletonInsightsForOneDay';
import InsightsEmptyData from '../InsightsForOneDay/InsightsEmptyData';
import { insightsMultipleDaysTimeline, resetMultipleDaysInsights } from '../../insightsSlice';
import { getDate } from 'utils/share';

const InsightsForMultipleDays = ({ standupDetail, selectedDateRange }) => {
    const { multipleDayInsights, insightsLoading } = useSelector((state) => state.insights);
    const dispatch = useDispatch();
    const theme = useTheme();
    return (
        <>
            {!insightsLoading && multipleDayInsights?.Questions?.length > 0 ? (
                <Box>
                    {multipleDayInsights?.Questions?.map((questionData) => (
                        <MainCard
                            key={questionData?.order}
                            border={false}
                            headerSX={{ padding: '10px 24px 10px 24px' }}
                            sx={{
                                mb: 3,
                                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)',
                                ':hover': {
                                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: '100%'
                                }
                            }}
                            title={
                                <Grid
                                    item
                                    sx={{
                                        height: '70px',
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'center'
                                    }}
                                >
                                    <FiberManualRecordIcon
                                        fontSize="small"
                                        sx={{
                                            fontSize: 20,
                                            color: questionData?.questionColor,
                                            marginRight: 2,
                                            ml: 2
                                        }}
                                    />
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            [theme.breakpoints.down('lg')]: {
                                                fontSize: '14px'
                                            }
                                        }}
                                    >
                                        {questionData?.question}
                                    </Typography>
                                </Grid>
                            }
                        >
                            <Grid item sx={{ maxHeight: '457px', overflow: 'auto' }}>
                                <Grid
                                    item
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    {multipleDayInsights?.Questions?.map((item, i) => (
                                        <Grid key={i} container direction="column" sx={{ width: '100%' }}>
                                            {item?.question === questionData?.question && (
                                                <Grid container direction="row">
                                                    <Grid item xs={6} md={8}>
                                                        <Box
                                                            display="flex"
                                                            flexDirection="column"
                                                            alignItems="start"
                                                            justifyContent="start"
                                                            sx={{ pl: 3 }}
                                                        >
                                                            <Typography variant="div" sx={{ mr: 1, mb: 2, fontSize: '1rem' }}>
                                                                <Typography
                                                                    variant="div"
                                                                    sx={{ mr: 1, mb: 2, fontSize: '1rem', fontWeight: 'bolder' }}
                                                                >
                                                                    {item?.totalParticipants}
                                                                </Typography>
                                                                participants gave{' '}
                                                                <Typography
                                                                    variant="div"
                                                                    sx={{ mr: 1, mb: 2, fontSize: '1rem', fontWeight: 'bolder' }}
                                                                >
                                                                    {item?.totalResponse}
                                                                </Typography>
                                                                responses in this period
                                                            </Typography>
                                                            <Typography variant="div" sx={{ mr: 1, mb: 2 }}>
                                                                Check them out in the
                                                                <Link
                                                                    component="button"
                                                                    variant="body2"
                                                                    onClick={() => {
                                                                        dispatch(resetMultipleDaysInsights());
                                                                        dispatch(
                                                                            insightsMultipleDaysTimeline({
                                                                                isTimelineClick: true,
                                                                                questionId: item?.questionId,
                                                                                standupId: standupDetail?.id,
                                                                                fromDate: getDate(selectedDateRange.startDate),
                                                                                toDate: getDate(selectedDateRange.endDate)
                                                                            })
                                                                        );
                                                                    }}
                                                                >
                                                                    Timeline
                                                                </Link>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </MainCard>
                    ))}
                </Box>
            ) : !insightsLoading && multipleDayInsights?.Questions?.length === 0 ? (
                <InsightsEmptyData />
            ) : (
                <SkeletonInsightsForOneDay />
            )}
        </>
    );
};

export default InsightsForMultipleDays;
