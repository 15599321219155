import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Button, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import useStyle from '../../useStyle';
import { preResponseData } from 'utils/common';

const PreDefineResponsDialog = ({
    open,
    handleClosePreDefineResponse,
    handleCloseBothDialog,
    Transition,
    onQuestionTypeChange,
    itemIndex,
    item
}) => {
    let list = item.questionType === 'pre-define' ? item.responseList : preResponseData;
    const [responseList, setResponseList] = useState(JSON.parse(JSON.stringify(list)));
    const isEdit = item.questionType === 'pre-define' ? true : false;
    const { outlinedInputSXProps } = useStyle();

    const onDeleteResponseHandler = (item) => {
        const itemsArray = responseList.filter((items) => items.id !== item.id);
        setResponseList(itemsArray);
    };
    useEffect(() => {}, [responseList]);
    const getPreResponseField = (item, index) => {
        return (
            <OutlinedInput
                fullWidth
                type={item?.type}
                id={item?.id}
                name={item?.name}
                sx={outlinedInputSXProps}
                endAdornment={
                    <InputAdornment position="end">
                        {responseList?.length > 2 && (
                            <IconButton onClick={() => onDeleteResponseHandler(item)}>
                                <CloseIcon />
                            </IconButton>
                        )}
                    </InputAdornment>
                }
                defaultValue={item?.responseValue}
                onChange={(e) => {
                    setResponseList((prevValue) => {
                        prevValue[index].responseValue = e.target.value;
                        return [...prevValue];
                    });
                }}
                placeholder={item?.placeholder}
            />
        );
    };

    const addResponseHandler = (e) => {
        e.preventDefault();

        setResponseList((prevValue) => {
            let responseValue = `response${Date.now()}`;
            return [
                ...prevValue,
                {
                    id: responseValue,
                    name: responseValue,
                    responseValue: '',
                    type: 'text',
                    placeholder: 'Enter response...'
                }
            ];
        });
    };
    const onSaveResponsehandler = (e) => {
        e.preventDefault();
        onQuestionTypeChange('pre-define', itemIndex, [...responseList]);

        preResponseData.forEach((ele, index) => {
            preResponseData[index].responseValue = '';
        });

        handleCloseBothDialog();
    };

    return (
        <Dialog fullScreen open={open} onClose={handleClosePreDefineResponse} TransitionComponent={Transition}>
            <Box sx={{ margin: '45px 45px 10px 45px', width: '95%' }}>
                <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <IconButton
                        component="button"
                        size="large"
                        sx={{
                            border: ' 2px solid #b1b2b3',
                            borderRadius: '100%',
                            color: '#b1b2b3',
                            textAlign: 'center',
                            height: '48px',
                            width: '48px'
                        }}
                        onClick={handleClosePreDefineResponse}
                    >
                        <ArrowBackIcon fontSize="inherit" />
                    </IconButton>

                    <IconButton
                        component="button"
                        size="large"
                        sx={{
                            border: ' 2px solid #b1b2b3',
                            borderRadius: '100%',

                            textAlign: 'center',
                            height: '48px',
                            width: '48px',
                            color: '#b1b2b3'
                        }}
                        onClick={handleCloseBothDialog}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Stack>
            </Box>
            <Box sx={{ margin: '10px auto', width: '50%' }}>
                <Stack spacing={4} alignItems="center">
                    <Stack>
                        <Typography variant="h1" sx={{ marginBottom: '12px', textAlign: 'center', fontWeight: '900' }}>
                            Pre-defined
                        </Typography>
                        <Typography variant="h5">Users can select from a list of pre-defined responses.</Typography>
                    </Stack>
                    <Stack sx={{ width: '100%' }}>
                        {responseList?.map((item, index) => (
                            <Stack sx={{ border: '1px solid', borderRadius: '8px', p: 1, m: 1 }} key={item.id}>
                                {getPreResponseField(item, index)}
                            </Stack>
                        ))}

                        <Button
                            onClick={addResponseHandler}
                            sx={{ width: 'fit-content', pl: 0 }}
                            disabled={!responseList?.every((item) => item.responseValue.length !== 0)}
                        >
                            + Add Response
                        </Button>
                    </Stack>
                    <Button
                        variant="contained"
                        disabled={!responseList?.every((item) => item.responseValue.length)}
                        onClick={onSaveResponsehandler}
                    >
                        {isEdit ? 'Update' : 'Save'} Response
                    </Button>
                </Stack>
            </Box>
        </Dialog>
    );
};

export default PreDefineResponsDialog;
