export const daysList = [
    { id: 0, title: 'Weekly', week: 1, key: 'weekly' },
    { id: 1, title: '2 week period', week: 2, key: '2_week' },
    { id: 2, title: '3 week period', week: 3, key: '3_week' },
    { id: 3, title: '4 week period', week: 4, key: '4_week' },
    { id: 4, title: 'First chosen day(s) of every month', week: 1, key: 'first_days_month' },
    { id: 5, title: 'Last chosen day(s) of every month', week: 1, key: 'last_days_month' },
    { id: 6, title: 'One time Only(not Repeated)', week: 0, key: 'onetime' }
];
export const WeekDay = [
    { title: 'M', value: 1 },
    { title: 'T', value: 2 },
    { title: 'W', value: 3 },
    { title: 'T', value: 4 },
    { title: 'F', value: 5 },
    { title: 'S', value: 6 },
    { title: 'S', value: 7 }
];
export const draggableField = [
    {
        id: 'question1',
        accessor: 'question1',
        key: 'question1',
        title: 'question1',
        name: 'question1',

        type: 'text',
        questionType: 'plain-text',
        title: 'What did you do since {lastReportedAt}?',
        placeholder: 'Type your custom intro message or leave blank for default…',
        color: '#0747a6'
    },
    {
        id: 'question2',
        accessor: 'question2',
        key: 'question2',
        title: 'question2',
        name: 'question2',
        type: 'text',

        questionType: 'plain-text',
        title: 'What will you do today?',
        placeholder: 'Type your custom intro message or leave blank for default…',
        color: '#008da6'
    },
    {
        id: 'question3',
        accessor: 'question3',
        key: 'question3',
        title: 'question3',
        name: 'question3',
        type: 'text',

        questionType: 'plain-text',
        title: 'Anything blocking your progress?',
        placeholder: 'Type your custom intro message or leave blank for default…',
        color: '#bf2600'
    },
    {
        id: 'question4',
        accessor: 'question4',
        key: 'question4',
        title: 'question4',
        name: 'question4',
        type: 'text',

        questionType: 'plain-text',
        title: 'How do you feel today? (Use Emoji)',
        placeholder: 'Type your custom intro message or leave blank for default…',
        color: '#b3d4ff'
    }
];
export const preResponseData = [
    {
        id: 'response1',
        name: 'response1',
        responseValue: '',
        type: 'text',
        placeholder: '01. Enter response...'
    },
    {
        id: 'response2',
        name: 'response2',
        type: 'text',
        responseValue: '',
        placeholder: '02. Enter response...'
    }
];
export const defaultIntroMessage = `{hello} It's time for our standup meeting {standup}. When you are ready, please answer the following question:`;
export const defaultOutroMessage = `Good job! 🎉`;
export const AddVariableData = [
    {
        id: 1,
        variableName: '{hello}',
        description: 'Adds a greeting and the first name of the user.'
    },
    { id: 2, variableName: '{name}', description: ' Adds the first name of the user or their username.' },
    { id: 3, variableName: '{standup}', description: 'Adds the name of the standup, survey or report.' },
    { id: 4, variableName: '{bye}', description: 'Adds a closing message to end the report.' }
];
export const colorPickerData = ['#0747a6', '#008da6', '#bf2600', '#b3d4ff'];
