import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, InputAdornment, Tooltip, InputLabel, TextField, Button } from '@mui/material';
import { IconHelp } from '@tabler/icons';
import LivePreviewBox from './livePreview/LivePreviewBox';
import AddVariablePopupDetails from './popupComponents/AddVariablePopupDetails';
import { useTheme } from '@mui/material/styles';
import DragDropContainer from './drag-drop-container/DragDropContainer';
import useQuestions from '../hooks/useQuestions';
import useStyle from '../useStyle';
const Questions = (props) => {
    const { isEditStandUp, standupDetail } = props;
    const { introMessageSXProps, outroMessageSXProps } = useStyle();
    const {
        onAddVariableClickHandler,
        disabledButtonHandler,
        list,
        setList,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        setFieldValue,
        theme,
        addQuestion,
        liveIntroMessage,
        liveOutroMessage,
        time,
        standup,
        firstName
    } = useQuestions({ isEditStandUp, standupDetail });

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start'
            }}
        >
            <Box display="flex" flexDirection="row" alignItems="center">
                <InputLabel sx={{ marginRight: 0.5 }} htmlFor="introMessage">
                    Intro message
                </InputLabel>
                <Tooltip
                    title={`Personalize TixoBot’s intro message. Use "{standup}" or "{name}" to add a more personal note.`}
                    placement="top"
                >
                    <div>
                        <IconHelp stroke={1.5} size="1rem" color={theme.palette.grey[500]} />{' '}
                    </div>
                </Tooltip>
            </Box>
            <TextField
                fullWidth
                id="introMessage"
                name="introMessage"
                type="text"
                sx={introMessageSXProps}
                value={values.introMessage}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                    maxLength: 201
                }}
                error={touched.introMessage && Boolean(errors.introMessage)}
                helperText={errors.introMessage}
                placeholder="Type your custom intro message or leave blank for default…"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <AddVariablePopupDetails list={list} flag={'intro'} onAddVariableClickHandler={onAddVariableClickHandler} />
                        </InputAdornment>
                    )
                }}
            />
            <LivePreviewBox message={liveIntroMessage} time={time} standup={standup} userName={firstName} flag={'intro'} />

            <Box display="flex" flexDirection="row" alignItems="center">
                <InputLabel sx={{ marginRight: 0.5 }} htmlFor="questions">
                    Questions
                </InputLabel>
                <Tooltip
                    title={`We have prefilled a set of questions for you. Use {last_report_date} for date of previous report.`}
                    placement="top"
                >
                    <div>
                        <IconHelp stroke={1.5} size="1rem" color={theme.palette.grey[500]} />{' '}
                    </div>
                </Tooltip>
            </Box>
            <DragDropContainer list={list} setList={setList} />

            <Button onClick={addQuestion} sx={{ width: 'fit-content', pl: 0 }} disabled={!list?.every(disabledButtonHandler)}>
                + Add Questions
            </Button>
            <Box display="flex" flexDirection="row" alignItems="center">
                <InputLabel sx={{ marginRight: 0.5 }} htmlFor="introMessage">
                    Outro message
                </InputLabel>
                <Tooltip
                    title={`Personalize TixoBot’s outro message. Use emojis to add some fun and color to your message.`}
                    placement="top"
                >
                    <div>
                        <IconHelp stroke={1.5} size="1rem" color={theme.palette.grey[500]} />{' '}
                    </div>
                </Tooltip>
            </Box>
            <TextField
                fullWidth
                id="outroMessage"
                name="outroMessage"
                type="text"
                sx={outroMessageSXProps}
                value={values.outroMessage}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                    maxLength: 201
                }}
                error={touched.outroMessage && Boolean(errors.outroMessage)}
                helperText={touched.outroMessage && errors.outroMessage}
                placeholder="Type your custom Outro message or leave blank for default…"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <AddVariablePopupDetails list={list} flag={'outro'} onAddVariableClickHandler={onAddVariableClickHandler} />
                        </InputAdornment>
                    )
                }}
            />
            <LivePreviewBox message={liveOutroMessage} time={time} standup={standup} userName={firstName} flag={'outro'} />
        </Box>
    );
};

export default Questions;
