import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { PersistGate } from 'redux-persist/es/integration/react';
// style + assets
import 'assets/scss/style.scss';
import config from './config';

import { persistStore } from 'redux-persist';
import Loader from 'ui-component/Loader';
import { store } from '../src/store/index';

let persistor = persistStore(store);
// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
