import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getStandUpDetailData, setManageStandupPermission } from '../AddStandUp/standupSlice';
import Loader from 'ui-component/Loader';
import useStyle from './useStyle';
const ManageStandUps = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const { user } = useSelector((state) => state.login);
    const { loading } = useSelector((state) => state.standups);
    const { manageStandupWrapper, manageStandupMessageWrapper } = useStyle();

    const [currentUserPermission, setCurrentUserPermission] = useState(false);
    let currentUserId = user.slackUserId;
    useEffect(() => {
        dispatch(getStandUpDetailData(id))
            .unwrap()
            .then((res) => {
                if (res.data) {
                    let currentUserPermissions = res.data?.Participants?.filter((participants) =>
                        participants?.User?.slackUserId?.includes(currentUserId)
                    )[0]?.isManage;
                    setCurrentUserPermission(currentUserPermissions);
                    if (!currentUserPermissions) {
                        setMessage('You have no manage permission');
                    }
                } else {
                    setMessage('No standup found');
                }
            })
            .catch((err) => console.log('err', err));
    }, [id]);

    useEffect(() => {
        dispatch(setManageStandupPermission(currentUserPermission));
    }, [currentUserPermission]);
    return (
        <Stack sx={manageStandupWrapper}>
            {loading ? (
                <>
                    <Loader />
                    <Stack alignItems="center" sx={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </Stack>
                </>
            ) : (
                <Stack sx={manageStandupMessageWrapper}>
                    <Typography variant="h3"> {message} </Typography>
                </Stack>
            )}
        </Stack>
    );
};

export default ManageStandUps;
