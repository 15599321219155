import { Button, IconButton, Stack, Typography } from '@mui/material';
import PopupState, { bindMenu, bindHover } from 'material-ui-popup-state';

import ReadMoreIcon from '@mui/icons-material/ReadMore';
import React from 'react';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { AddVariableData } from 'utils/common';
const AddVariablePopupDetails = ({ onAddVariableClickHandler, flag }) => {
    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <>
                    <IconButton variant="contained" {...bindHover(popupState)} sx={{ p: '10px' }} aria-label="search">
                        <ReadMoreIcon />
                    </IconButton>
                    <HoverMenu {...bindMenu(popupState)}>
                        <Stack sx={{ width: '100%', maxWidth: 180, p: 1.5, bgcolor: 'background.paper' }}>
                            <Typography variant="h5" sx={{ mb: 1 }}>
                                Add a variable:
                            </Typography>
                            {AddVariableData.map((variable) => (
                                <Stack key={variable.id}>
                                    <Stack
                                        sx={{
                                            background: '#eee',
                                            width: '100%',
                                            borderRadius: '6px',
                                            padding: '1px',
                                            display: 'flex',
                                            alignItems: 'flex-start'
                                        }}
                                        onClick={() => onAddVariableClickHandler(variable.variableName, flag)}
                                    >
                                        <Typography sx={{ padding: '2px', fontWeight: 300 }} variant="h6" component={Button}>
                                            {variable.variableName}
                                        </Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography
                                            sx={{ padding: '2px', color: '#57585a', fontWeight: 300 }}
                                            variant="h6"
                                            component={'div'}
                                        >
                                            {variable.description}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    </HoverMenu>
                </>
            )}
        </PopupState>
    );
};

export default AddVariablePopupDetails;
