import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useFormikContext } from 'formik';

const DayPicker = ({ day, setSelectedDays, isEditStandUp, standupDetail }) => {
    const theme = useTheme();
    const { values } = useFormikContext();
    let today = moment().isoWeekday();
    let WeekDay = [
        { title: 'M', value: 1 },
        { title: 'T', value: 2 },
        { title: 'W', value: 3 },
        { title: 'T', value: 4 },
        { title: 'F', value: 5 },
        { title: 'S', value: 6 },
        { title: 'S', value: 0 }
    ];
    const [selected, setSelected] = React.useState(
        [...Array(4)].map((e, index) =>
            isEditStandUp
                ? standupDetail?.scheduleDays[index] ?? values?.scheduleDays[index] ?? []
                : index === 0
                ? values?.scheduleDays[0] ?? Array(1, 2, 3, 4, 5)
                : values?.scheduleDays[index] ?? []
        )
    );

    const handleClick = (weekIndex, index) => {
        let tempArray = JSON.parse(JSON.stringify(selected));

        if (tempArray[weekIndex]?.includes(index)) {
            tempArray[weekIndex] = tempArray[weekIndex].filter((e) => e !== index);
        } else {
            tempArray[weekIndex]?.push(index);
        }
        const slicedArray = tempArray.slice(0, day);

        setSelected(tempArray);
        setSelectedDays(slicedArray);
    };

    useEffect(() => {
        const slicedArray = selected.slice(0, day);
        setSelectedDays(slicedArray);
    }, [day, selected, setSelectedDays]);
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {Array(day)
                .fill(0)
                .map((_, weekIndex) => {
                    return (
                        <Box
                            key={weekIndex + `key${new Date()}`}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                margin: '5px 0px'
                            }}
                        >
                            {WeekDay.map((item, index) => {
                                return (
                                    <>
                                        <Box
                                            key={item.value}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <span
                                                style={{
                                                    height: '15px',
                                                    lineHeight: '13px',
                                                    color: theme.palette.grey[500],
                                                    marginRight: '2px',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {item.value === today && weekIndex === 0 ? 'Today' : ''}
                                            </span>
                                            <Box
                                                sx={{
                                                    width: '40px',
                                                    lineHeight: '40px',
                                                    height: '40px',
                                                    borderRadius: '50%',
                                                    border: !selected[weekIndex]?.includes(item.value) ? '2px solid' : 'none',
                                                    borderColor: !selected[weekIndex]?.includes(item.value)
                                                        ? theme.palette.darkTextPrimary
                                                        : 'none',
                                                    fontSize: '18px',
                                                    background: !selected[weekIndex]?.includes(item.value)
                                                        ? theme.palette.backgroundDefault
                                                        : theme.palette.primary.dark,
                                                    color: !selected[weekIndex]?.includes(item.value)
                                                        ? theme.palette.darkTextPrimary
                                                        : theme.palette.secondary.light,
                                                    margin: '0px 5px',
                                                    textAlign: 'center',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => handleClick(weekIndex, item?.value)}
                                            >
                                                {item.title}
                                            </Box>
                                        </Box>
                                    </>
                                );
                            })}
                        </Box>
                    );
                })}
        </Box>
    );
};

export default DayPicker;
