import { Skeleton, Grid, Divider } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';

const InsightsReportStreakSkeleton = () => {
    return (
        <MainCard
            border={false}
            sx={{
                width: '100%',
                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)',
                ':hover': {
                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)'
                }
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Skeleton variant="text" sx={{ width: '40%' }} />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                    <Grid item xs={12} display="flex" justifyContent="start" alignItems="center" sx={{ mt: 3 }}>
                        <Grid item xs={12} display="flex" justifyContent="start" alignItems="center">
                            <Grid item xs={6} display="flex" justifyContent="start" alignItems="center">
                                <Skeleton variant="circular" sx={{ width: '70px', height: '70px' }} />{' '}
                            </Grid>
                            <Grid item xs={12} sx={{ mr: 1 }}>
                                <Grid item xs={12}>
                                    <Skeleton variant="text" sx={{ width: '100%' }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton variant="text" sx={{ width: '70%' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} display="flex" justifyContent="center">
                            <Skeleton variant="rounded" width={50} height={50} />
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                    <Grid item xs={12} display="flex" justifyContent="start" alignItems="center" sx={{ mt: 3 }}>
                        <Grid item xs={12} display="flex" justifyContent="start" alignItems="center">
                            <Grid item xs={6} display="flex" justifyContent="start" alignItems="center">
                                <Skeleton variant="circular" sx={{ width: '70px', height: '70px' }} />{' '}
                            </Grid>
                            <Grid item xs={12} sx={{ mr: 1 }}>
                                <Grid item xs={12}>
                                    <Skeleton variant="text" sx={{ width: '100%' }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton variant="text" sx={{ width: '70%' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} display="flex" justifyContent="center">
                            <Skeleton variant="rounded" width={50} height={50} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default InsightsReportStreakSkeleton;
