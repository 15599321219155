import * as React from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useStyle from '../../useStyle';
import { reduceColorOpacity } from 'utils/share';
import DoneIcon from '@mui/icons-material/Done';
import { colorPickerData } from 'utils/common';

const ColorPopover = ({ handleClose, open, anchorEl, onColorChange, itemIndex, item }) => {
    const { questionColorPalette } = useStyle();
    return (
        <Box>
            <Menu
                id="color-picker"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'questionPopover'
                }}
            >
                {colorPickerData.map((color, index) => {
                    return (
                        <MenuItem key={index} onClick={handleClose}>
                            {Object.values(reduceColorOpacity(color)).map((i, index) => {
                                return (
                                    <Stack
                                        onClick={() => onColorChange(i[index], itemIndex)}
                                        sx={{
                                            ...questionColorPalette,
                                            background: `${i[index]}`
                                        }}
                                        key={index}
                                    >
                                        <IconButton
                                            sx={{
                                                color: `${item.color === i[index] ? 'white' : 'transparent'}`,
                                                textAlign: 'center',
                                                padding: '5px'
                                            }}
                                        >
                                            <DoneIcon />
                                        </IconButton>
                                    </Stack>
                                );
                            })}
                        </MenuItem>
                    );
                })}
            </Menu>
        </Box>
    );
};

export default ColorPopover;
