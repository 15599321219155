import React from 'react';
import MainCard from 'ui-component/cards/MainCard';

import { Stack, Divider, Grid, Typography, Avatar, Skeleton, Box, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import InsightsReportStreakSkeleton from './InsightsReportStreakSkeleton';
import { convertRange } from 'utils/share';
const InsightsReportStreak = ({ reportType, streakData, question }) => {
    const { insightsLoading } = useSelector((state) => state.insights);
    const theme = useTheme();
    var ar = streakData?.length && streakData?.map((el) => el.streak);

    function getMax(ar) {
        if (ar.length <= 0 && ar.length > 0) return ar;
        let max = [...Array(ar?.length)].map((e, index) => ({
            value: ar[index],
            index: index
        }));

        max.sort((a, b) => a.value - b.value);

        for (let i = ar?.length; i < ar.length; i++) {
            if (ar[i] > max[0].value) {
                max[0] = { value: ar[i], index: i };
                max.sort((a, b) => a.value - b.value);
            }
        }
        return max;
    }

    let result = ar?.length ? getMax(ar) : [];
    let finalData = [...result].reverse();

    return (
        <>
            {!insightsLoading && question?.length > 0 ? (
                <>
                    {streakData?.length ? (
                        <MainCard
                            border={false}
                            sx={{
                                width: '100%',
                                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)',
                                ':hover': {
                                    boxShadow: '0 2px 14px 0 rgb(32 40 45 / 14%)'
                                }
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h2">Report {reportType === 'reportStreak' ? 'Streak' : 'Champion'} ✨</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    justifyContent="center"
                                    flexDirection="column"
                                    alignItems="center"
                                    sx={{ mt: 3 }}
                                >
                                    {finalData?.map((streak, i) => {
                                        return (
                                            <>
                                                <Box
                                                    item
                                                    key={i}
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    sx={{ width: '100%', m: 1 }}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        display="flex"
                                                        justifyContent="start"
                                                        alignItems="center"
                                                        sx={{ pt: 1 }}
                                                    >
                                                        <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                                                            <Avatar
                                                                src={streakData[streak.index]?.User?.image72}
                                                                sx={{
                                                                    [theme.breakpoints.up('md')]: {
                                                                        width: i === 0 ? '50px' : '40px',
                                                                        height: i === 0 ? '50px' : '40px'
                                                                    },
                                                                    marginRight: '5px',
                                                                    width: i === 0 ? '70px' : '55px',
                                                                    height: i === 0 ? '70px' : '55px'
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={8}
                                                            display="flex"
                                                            justifyContent="start"
                                                            alignItems="start"
                                                            flexDirection="column"
                                                        >
                                                            <Typography
                                                                variant="h4"
                                                                textAlign="center"
                                                                noWrap
                                                                sx={{
                                                                    [theme.breakpoints.up('md')]: {
                                                                        fontSize: i === 0 ? '14px' : '11px'
                                                                    },
                                                                    fontSize: i === 0 ? '20px' : '16px'
                                                                }}
                                                            >
                                                                {streakData[streak.index]?.User?.firstName}{' '}
                                                                {streakData[streak.index]?.User?.lastName}
                                                            </Typography>{' '}
                                                            <Stack
                                                                direction="row"
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                sx={{ mt: 1 }}
                                                                spacing={1}
                                                            >
                                                                <Typography
                                                                    variant="div"
                                                                    textAlign="center"
                                                                    sx={{
                                                                        [theme.breakpoints.up('md')]: {
                                                                            fontSize: '15px'
                                                                        },
                                                                        fontSize: '18px',
                                                                        fontWeight: 600,
                                                                        color: 'black'
                                                                    }}
                                                                >
                                                                    {streak.value}
                                                                </Typography>
                                                                <Typography
                                                                    variant="div"
                                                                    textAlign="center"
                                                                    sx={{
                                                                        [theme.breakpoints.up('md')]: {
                                                                            fontSize: '10px'
                                                                        }
                                                                    }}
                                                                >
                                                                    {reportType === 'reportStreak'
                                                                        ? 'consecutive reports'
                                                                        : 'total reports'}
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant="div"
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            textAlign="center"
                                                            sx={{
                                                                [theme.breakpoints.up('md')]: {
                                                                    fontSize: i === 0 ? '2.5rem' : '1.5rem'
                                                                },
                                                                fontSize: i === 0 ? '3rem' : '2rem'
                                                            }}
                                                        >
                                                            {reportType === 'reportStreak'
                                                                ? '🔥'
                                                                : i === 0
                                                                ? '🥇'
                                                                : i === 1
                                                                ? '🥈'
                                                                : i === 2
                                                                ? '🥉'
                                                                : ''}
                                                        </Typography>
                                                    </Grid>
                                                </Box>

                                                {i === 0 && <Divider orientation="horizontal" sx={{ width: '100%' }} />}
                                            </>
                                        );
                                    })}
                                </Grid>
                                <Divider />
                            </Grid>
                        </MainCard>
                    ) : (
                        <></>
                    )}
                </>
            ) : !insightsLoading && question?.length === 0 ? (
                <></>
            ) : (
                <InsightsReportStreakSkeleton />
            )}
        </>
    );
};

export default InsightsReportStreak;
