import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addAdmin, getAllAdmin, removeAdmin } from 'services/admin.services';
import { getAllParticipants } from 'services/participants.services';

const initialState = {
    users: [],
    admins: [],
    participants: [],
    error: '',
    getUserLoading: false,
    getAdminLoading: false,
    isAdminAdded: false,
    addAdminLoading: false,
    message: '',
    removeAdminLoading: false,
    isAdminRemove: false,
    isAdmin: false
};

export const getAllAdminUsers = createAsyncThunk('admin/getAllAdminUsers', async () => {
    const response = await getAllAdmin()
        .then((res) => {
            return res.data;
        })
        .catch((err) => err);

    return response.data;
});
export const getAllUsers = createAsyncThunk('admin/getAllUsers', async () => {
    const response = await getAllParticipants().then((res) => {
        let participants = res?.members;
        participants = participants.filter(({ is_bot, name, deleted }) => !is_bot && name !== 'slackbot' && !deleted);
        return participants ?? [];
    });

    return response;
});
export const addAdminUser = createAsyncThunk('admin/addAdminUser', async (payload) => {
    const response = await addAdmin(payload);

    return response.data;
});
export const removeAdminUser = createAsyncThunk('admin/removeAdminUser', async (payload) => {
    const response = await removeAdmin(payload);

    return response.data;
});

export const adminSlice = createSlice({
    name: 'admin',
    initialState,

    reducers: {
        resetAdminLoading: (state) => {
            state.isAdminAdded = false;
            state.isAdminRemove = false;
        }
    },

    extraReducers: {
        [getAllUsers.pending]: (state) => {
            state.getUserLoading = true;
        },

        [getAllUsers.fulfilled]: (state, action) => {
            state.users = action.payload;

            state.getUserLoading = false;
        },

        [getAllUsers.rejected]: (state, { error }) => {
            state.error = error;
            state.getUserLoading = false;
        },
        [getAllAdminUsers.pending]: (state) => {
            state.getAdminLoading = true;
        },

        [getAllAdminUsers.fulfilled]: (state, action) => {
            state.admins = action.payload;

            state.getAdminLoading = false;
        },

        [getAllAdminUsers.rejected]: (state, { error }) => {
            state.error = error;
            state.getAdminloading = false;
        },
        [addAdminUser.pending]: (state) => {
            state.addAdminLoading = true;
        },

        [addAdminUser.fulfilled]: (state, action) => {
            state.message = action.payload;
            state.isAdminAdded = true;
            state.addAdminLoading = false;
        },

        [addAdminUser.rejected]: (state, { error }) => {
            state.error = error;
            state.addAdminLoading = false;
        },
        [removeAdminUser.pending]: (state) => {
            state.removeAdminLoading = true;
        },

        [removeAdminUser.fulfilled]: (state, action) => {
            state.message = action.payload;
            state.isAdminRemove = true;
            state.removeAdminLoading = false;
        },

        [removeAdminUser.rejected]: (state, { error }) => {
            state.error = error;
            state.removeAdminLoading = false;
        }
    }
});

export const { resetAdminLoading } = adminSlice.actions;

export default adminSlice.reducer;
