import { useTheme } from '@emotion/react';
import { Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAllParticipants, getChannelParticipants } from 'services/participants.services';

const useParticipants = ({ isEditStandUp, standupDetail }) => {
    const { values, handleChange, handleBlur, touched, errors, setFieldValue } = useFormikContext();
    const theme = useTheme();
    const [participants, setParticipants] = React.useState(values?.participants?.length ? values?.participants : []);
    const [channelMembers, setChannelMembers] = React.useState([]);
    const [manageModal, setManageModal] = React.useState(false);
    const [removeModal, setRemoveModal] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [nextCursor, setNextCursor] = React.useState();
    const channel = 'C03LGPJM83A';
    const permissions = { isView: true, isReport: true, isManage: false };
    const fullPermissions = { isView: true, isReport: true, isManage: true };
    const { user: currentUser } = useSelector((state) => state.login);

    useEffect(() => {
        getAllParticipants().then((res) => {
            let participants = res?.members;
            participants = participants.filter(({ is_bot, name, deleted }) => !is_bot && name !== 'slackbot' && !deleted);
            setParticipants(participants || []);
        });
    }, []);
    useEffect(() => {
        if (participants?.length) {
            const channelParticipants = values?.participants?.length ? values?.participants : mapCurrentParticipant();
            !isEditStandUp && setFieldValue('participants', channelParticipants);
        }
        //  && setFieldValue('participants', editParticipants);
    }, [participants, isEditStandUp]);
    useEffect(() => {
        if (nextCursor !== '')
            getChannelParticipants(channel, nextCursor)
                .then((res) => {
                    setChannelMembers((prev) => [...prev, ...res?.members]);
                    setNextCursor(res?.response_metadata?.next_cursor);
                })
                .catch((error) => console.log(error));
    }, [nextCursor]);

    const Pop = (props) => {
        const { className, anchorEl, style, ...rest } = props;
        const bound = anchorEl.getBoundingClientRect();
        return (
            <Stack
                {...rest}
                style={{
                    position: 'absolute',
                    zIndex: 9999,
                    width: bound.width
                }}
            />
        );
    };
    const checkBoxHandler = (event, id) => {
        const updateParticipants = values.participants.map((user) =>
            user.id === id
                ? {
                      ...user,
                      permissions: { ...user.permissions, slackId: id, [event.target.name]: event.target.checked }
                  }
                : user
        );
        setFieldValue('participants', updateParticipants);
    };
    const handleManageConfirm = () => {
        let updateParticipants = values.participants.map((member) =>
            member.id === currentUser.slackUserId
                ? {
                      ...member,
                      permissions: { ...member.permissions, slackId: currentUser.slackUserId, isManage: false }
                  }
                : member
        );
        setFieldValue('participants', updateParticipants);
        setManageModal(false);
    };
    const handleRemoveConfirm = () => {
        let newParticipation = values?.participants;
        newParticipation = newParticipation.filter((member) => {
            return member.id !== currentUser.slackUserId;
        });
        setFieldValue('participants', newParticipation);
        setRemoveModal(false);
    };
    const mapChannelParticipants = () => {
        setIsLoading(true);
        let channelMembersObj = [];
        if (participants?.length > 0 && channelMembers?.length > 0) {
            channelMembersObj = participants
                .filter(({ id }) => channelMembers.includes(id))
                .map((member) => ({
                    ...member,
                    permissions:
                        member.id === currentUser.slackUserId
                            ? { ...fullPermissions, slackId: member.id }
                            : { ...permissions, slackId: member.id }
                }));

            return channelMembersObj;
        }
        return channelMembersObj;
    };
    const mapCurrentParticipant = () => {
        let channelMembersObj = [];

        if (participants?.length > 0) {
            channelMembersObj = participants
                .filter(({ id }) => id === currentUser.slackUserId)
                .map((member) => ({
                    ...member,
                    permissions: { ...fullPermissions, slackId: member.id }
                }));

            setIsLoading(false);
            return channelMembersObj;
        }

        return channelMembersObj;
    };
    return {
        participants,
        setParticipants,
        Pop,
        channelMembers,
        nextCursor,
        values,
        theme,
        handleChange,
        handleBlur,
        touched,
        errors,
        setFieldValue,
        mapChannelParticipants,
        mapCurrentParticipant,
        checkBoxHandler,
        handleManageConfirm,
        handleRemoveConfirm,
        removeModal,
        setRemoveModal,
        manageModal,
        setManageModal,
        permissions,
        currentUser,
        isLoading
    };
};

export default useParticipants;
