// assets
import { IconKey } from '@tabler/icons';

// constant
const icons = {
    IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'standups',
    title: 'Standups',
    type: 'group',
    children: [
        {
            id: 'standups1',
            title: 'Standups',
            type: 'collapse',
            icon: icons.IconKey,

            children: [
                {
                    id: 'standups2',
                    title: 'Standups 1',
                    type: 'item',
                    url: ''
                },
                {
                    id: 'standups3',
                    title: 'Standups 2',
                    type: 'item',
                    url: ''
                }
            ]
        }
    ]
};

export default pages;
