import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getChannels } from 'services/channel.services';
import { standUpsInsights } from 'services/standup.services';

const initialState = {
    insights: {},
    singleDayInsights: {},
    multipleDayInsights: {},
    error: '',
    previousDayInsights: {},
    loading: false,
    insightsLoading: false,
    isMultipleDaysTimelineClick: false,
    isMultipleDaysTimeline: {
        questionId: '',
        isTimelineClick: false,
        fromDate: '',
        toDate: '',
        standupId: ''
    }
};

export const standupSingleDayInsights = createAsyncThunk('insights/standupSingleDayInsights', async (payload) => {
    const response = await standUpsInsights(payload);
    return response.data;
});
export const standupMultipleDayInsights = createAsyncThunk('insights/standupMultipleDayInsights', async (payload) => {
    const response = await standUpsInsights(payload);
    return response.data;
});
export const standUpPreviousInsights = createAsyncThunk('insights/standUpPreviousInsights', async (payload) => {
    const response = await standUpsInsights(payload);
    return response.data;
});

export const insightsSlice = createSlice({
    name: 'insights',
    initialState,

    reducers: {
        resetSingleDayInsights: (state) => {
            state.singleDayInsights = {};
            state.previousDayInsights = {};
        },
        resetMultipleDaysInsights: (state) => {
            state.multipleDayInsights = {};

            state.isMultipleDaysTimeline = initialState.isMultipleDaysTimeline;
        },
        insightsMultipleDaysTimeline: (state, { payload }) => {
            state.isMultipleDaysTimeline = { ...payload };
        }
    },

    extraReducers: {
        [standupSingleDayInsights.pending]: (state) => {
            state.insightsLoading = true;
        },
        [standupSingleDayInsights.fulfilled]: (state, { payload }) => {
            state.singleDayInsights = { ...state.singleDayInsights, ...payload.data };

            state.insightsLoading = false;
        },
        [standupSingleDayInsights.rejected]: (state, { error }) => {
            state.error = error;
            state.insightsLoading = false;
        },
        [standupMultipleDayInsights.pending]: (state) => {
            state.insightsLoading = true;
        },
        [standupMultipleDayInsights.fulfilled]: (state, { payload }) => {
            state.multipleDayInsights = { ...state.multipleDayInsights, ...payload.data };

            state.insightsLoading = false;
        },
        [standupMultipleDayInsights.rejected]: (state, { error }) => {
            state.error = error;
            state.insightsLoading = false;
        },
        [standUpPreviousInsights.pending]: (state) => {
            state.insightsLoading = true;
        },

        [standUpPreviousInsights.fulfilled]: (state, { payload }) => {
            if (Object.entries(payload.data).length !== 0) {
                state.previousDayInsights = { ...state.previousDayInsights, ...payload.data };
            }
            state.insightsLoading = false;
        },

        [standUpPreviousInsights.rejected]: (state, { error }) => {
            state.error = error;
            state.insightsLoading = false;
        }
    }
});

// Action creators are generated for each case reducer function
export const { resetMultipleDaysInsights, resetSingleDayInsights, insightsMultipleDaysTimeline } = insightsSlice.actions;
export default insightsSlice.reducer;
