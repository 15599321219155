import { combineReducers } from '@reduxjs/toolkit';
// reducer import
import customizationReducer from './customizationReducer';
import loginReducer from '../views/login/loginSlice';
import standupReducer from 'views/pages/AddStandUp/standupSlice';
import channelSlice from 'views/pages/AddStandUp/channelSlice';
import insightsReducer from 'views/pages/Insights/insightsSlice';
import adminReducer from 'views/pages/AdminDashboard/Admin/adminSlice';
// ==============================|| COMBINE REDUCER ||============================== //

const rootReducer = combineReducers({
    customization: customizationReducer,
    login: loginReducer,
    standups: standupReducer,
    channels: channelSlice,
    insights: insightsReducer,
    admin: adminReducer
});

export default rootReducer;
