import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/material/styles';
import { colorPickerData, draggableField } from 'utils/common';

import { useSelector } from 'react-redux';
import { getAllParticipants } from 'services/participants.services';
import { randomColorPicker } from 'utils/share';

const useQuestions = ({ isEditStandUp, standupDetail }) => {
    const { user } = useSelector((state) => state.login);
    const theme = useTheme();
    const { values, handleChange, handleBlur, touched, errors, setFieldValue, isSubmitting } = useFormikContext();
    const [list, setList] = useState(
        isEditStandUp
            ? values?.questionsList?.length
                ? values?.questionsList
                : JSON.parse(JSON.stringify(standupDetail?.Questions))
            : values?.questionsList?.length
            ? values?.questionsList
            : draggableField
    );

    const time = values?.time;
    const standup = values?.name;
    const { firstName } = user;

    const addQuestion = (e) => {
        e.preventDefault();

        setList((prevValue) => {
            let questionValue = `question${Date.now()}`;
            return [
                ...prevValue,
                {
                    id: questionValue,
                    accessor: questionValue,
                    key: questionValue,
                    name: questionValue,
                    type: 'text',
                    title: '',
                    color: randomColorPicker(colorPickerData),
                    questionType: 'plain-text',
                    placeholder: 'Type a question'
                }
            ];
        });
    };
    const disabledButtonHandler = (item) => item.title.length;
    const onAddVariableClickHandler = (value, flag) => {
        let introMessage = values.introMessage;
        let outroMessage = values.outroMessage;
        flag === 'intro'
            ? setFieldValue('introMessage', introMessage.concat(value))
            : setFieldValue('outroMessage', outroMessage.concat(value));
    };

    let liveIntroMessage = `${
        values.introMessage.length
            ? values.introMessage
            : isEditStandUp?.introMessage
            ? isEditStandUp?.introMessage
            : `Hello {name}! It's time for our standup meeting *${values.name}*. When you are ready, please answer the
following question:`
    } `;
    const liveOutroMessage = `${
        values.outroMessage.length ? values.outroMessage : isEditStandUp?.outroMessage ? isEditStandUp?.outroMessage : 'Good job! 🎉'
    } `;

    const onDeleteQuestionHandler = (item) => {
        if (list.length > 1) {
            const itemsArray = list.filter((items) => items.id !== item.id);
            setList(itemsArray);
        }
    };
    const onColorChangehandler = (color, itemIndex) => {
        setList((prevValue) => {
            prevValue[itemIndex].color = color;
            return [...prevValue];
        });
    };
    const onQuestionTypeChangehandler = (questionType, itemIndex, responseList) => {
        setList((prevValue) => {
            prevValue[itemIndex].questionType = questionType;
            questionType === 'pre-define' && (prevValue[itemIndex].responseList = responseList);
            return [...prevValue];
        });
    };
    // const getChangedPos = (result) => {
    //     const newArray = Array.from(list);
    //     const [removed] = newArray.splice(result.source.index, 1);
    //     newArray.splice(result.destination.index, 0, removed);
    //     setList(newArray);
    // };
    useEffect(() => {
        setFieldValue('questionsList', list);
    }, [list]);
    return {
        addQuestion,
        onAddVariableClickHandler,
        list,
        setList,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        setFieldValue,
        theme,
        disabledButtonHandler,
        liveIntroMessage,
        liveOutroMessage,
        onDeleteQuestionHandler,
        onColorChangehandler,
        onQuestionTypeChangehandler,
        // getChangedPos,
        time,
        standup,
        firstName
    };
};

export default useQuestions;
