import { InputBase, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import StandUpDetail from 'views/pages/StandUpDetail/StandUpDetail';
import { convertTimeFormatTo12Hour, separateMeridiem, separateTime } from 'utils/share';
import { useFormikContext } from 'formik';

const TimeInputField = ({ setSelectedTime, standupDetail, isEditStandUp }) => {
    const theme = useTheme();
    const { values } = useFormikContext();
    const [time, setTime] = React.useState(
        isEditStandUp
            ? separateTime(convertTimeFormatTo12Hour(standupDetail?.time))
            : values.time.length
            ? separateTime(convertTimeFormatTo12Hour(values.time))
            : '9:00'
    );
    const [meridiem, setMeridiem] = React.useState(
        isEditStandUp
            ? separateMeridiem(convertTimeFormatTo12Hour(standupDetail?.time))
            : values.time.length
            ? separateMeridiem(convertTimeFormatTo12Hour(values.time))
            : 'AM'
    );

    const onChangeTime = (event) => {
        setTime(event.target.value);
    };

    useEffect(() => {
        setSelectedTime(time + '' + meridiem);
    }, [time, meridiem, setSelectedTime, standupDetail, isEditStandUp]);

    const onBlurTime = (event) => {
        const value = event.target.value;
        const seconds = Math.max(0, getSecondsFromHHMMSS(value));
        const time = toHHMMSS(seconds);
        setTime(time);
    };
    const onChangeMeridiem = (event) => {
        setMeridiem(event.target.value);
    };

    const onBlurMeridiem = (event) => {
        const value = event.target.value;
        if (value === 'am' || value === 'AM' || value === 'a') setMeridiem('AM');
        if (value === 'pm' || value === 'PM' || value === 'p') setMeridiem('PM');
    };

    const getSecondsFromHHMMSS = (value) => {
        const [str1, str2, str3] = value.split(':');

        const val1 = Number(str1);
        const val2 = Number(str2);
        const val3 = Number(str3);

        if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
            return val1;
        }

        if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
            return val1 * 60 + val2;
        }

        if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
            return val1 * 60 * 60 + val2 * 60 + val3;
        }

        return 0;
    };

    const toHHMMSS = (secs) => {
        const secNum = parseInt(secs.toString(), 10);
        const hours = Math.floor(secNum / 3600);
        const minutes = Math.floor(secNum / 60) % 60;
        const seconds = secNum % 60;

        return [hours, minutes, seconds]
            .map((val) => (val < 10 ? `0${val}` : val))
            .filter((val, index) => val !== '00' || index > 0)
            .join(':')
            .replace(/^0/, '');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                height: '48px',
                border: '1px solid',
                borderRadius: `12px`,
                padding: '0px 10px'
            }}
        >
            <InputBase type="text" onChange={onChangeTime} onBlur={onBlurTime} value={time} />
            <InputBase type="text" onChange={onChangeMeridiem} onBlur={onBlurMeridiem} value={meridiem} />
        </Box>
    );
};

export default TimeInputField;
