import { Button, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import Modal from 'ui-component/Modal';
import { deleteStandUp, UpdateStandUpStatus } from 'services/standup.services';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStandup, updateStandUpStatus } from '../standupSlice';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
const Advanced = ({ data }) => {
    const [pauseModal, setPauseModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [standupStatus, setStandupStatus] = useState('0');
    const [currentStatus, setCurrentStatus] = useState('Resume');
    const theme = useTheme();
    const navigate = useNavigate();
    const { loading } = useSelector((state) => state.standups);
    const dispatch = useDispatch();
    useEffect(() => {
        if (data) {
            if (data.status === 'active') {
                setStandupStatus('1');
                setCurrentStatus('Pause');
            } else {
                setStandupStatus('0');
                setCurrentStatus('Resume');
            }
        }
    }, [data]);

    const handlePauseStandUp = () => {
        if (data && standupStatus) {
            let status = standupStatus === '1' ? '0' : '1';
            let id = data.id;
            dispatch(updateStandUpStatus({ id, status }))
                .then((res) => {
                    if (res.error) {
                        toast.error('Something went wrong!');
                    } else {
                        toast.success(`${status === '1' ? 'Resume' : 'Paused'} standup successfully!`);

                        if (status === '1') {
                            setStandupStatus('1');
                            setCurrentStatus('Pause');
                        } else {
                            setStandupStatus('0');
                            setCurrentStatus('Resume');
                        }
                    }
                })
                .catch((err) => toast.error('Something went wrong!'))
                .finally(() => setPauseModal(false));
        }
    };
    const handleDeleteStandUp = () => {
        if (data) {
            let id = data.id;
            dispatch(deleteStandup(id))
                .then((res) => {
                    if (res.error) {
                        toast.error('Something went wrong!');
                    } else {
                        toast.success('Deleted successfully!');
                        navigate('/dashboard');
                    }
                })
                .catch((err) => toast.error('Something went wrong!'));
        }
    };
    return (
        <MainCard content={false} border={false}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignContent="center" justifyContent="space-between">
                            <Grid item xs={6}>
                                <Grid item xs={10}>
                                    <Typography variant="h4">Pause this standup</Typography>
                                    <Typography variant="body1" mt={2}>
                                        {standupStatus === '1'
                                            ? "Pausing a standup will stop Tixobot from sending DM's to participants. All data will be retained."
                                            : `${data?.name} has been paused. Press the resume button below to activate it again.`}
                                    </Typography>
                                    <Button
                                        disableElevation
                                        variant="contained"
                                        color="warning"
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            borderRadius: '30px',
                                            marginTop: 2,
                                            color: theme.palette.grey[50],
                                            padding: '7px 25px',
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.dark
                                            }
                                        }}
                                        onClick={() => setPauseModal(!pauseModal)}
                                    >
                                        {currentStatus}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid item xs={10}>
                                    <Typography variant="h4">Delete this standup</Typography>
                                    <Typography variant="body2" mt={2}>
                                        {'Deleting a standup is permanent and non reversible. All data will be erased!'}
                                    </Typography>
                                    <Button
                                        disableElevation
                                        variant="contained"
                                        color="error"
                                        sx={{ borderRadius: '30px', marginTop: 2, padding: '7px 25px' }}
                                        onClick={() => setDeleteModal(!deleteModal)}
                                    >
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {
                <>
                    <Modal
                        open={pauseModal}
                        toggle={() => setPauseModal(!pauseModal)}
                        title={'Pause Stand Up'}
                        content={`Are you sure you want to ${currentStatus} this Standup?`}
                        LeftButton={'Cancel'}
                        RightButton={loading ? (currentStatus === 'Pause' ? 'Pausing...' : 'Resuming...') : currentStatus}
                        actionClick={handlePauseStandUp}
                    />
                    <Modal
                        open={deleteModal}
                        toggle={() => setDeleteModal(!deleteModal)}
                        title={'Delete this standup?'}
                        content={'Are you sure you want to Delete this Standup?'}
                        LeftButton={'Cancel'}
                        actionButtonColor={'error'}
                        RightButton={loading ? 'Deleting...' : 'Delete'}
                        actionClick={handleDeleteStandUp}
                    />
                </>
            }
        </MainCard>
    );
};

export default Advanced;
5;
