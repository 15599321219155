import { useTheme } from '@emotion/react';
import { Box, Button, InputLabel, MenuItem, OutlinedInput, Select, Tooltip } from '@mui/material';
import { IconHelp } from '@tabler/icons';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import DatePicker from 'ui-component/DatePicker';
import DayPicker from 'ui-component/DayPicker';
import TimeInputField from 'ui-component/TimeInputField';

import { daysList } from 'utils/common';
import { getScheduleDays, getTimeZoneList, getUserTimeZone } from 'utils/share';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const Schedule = ({ isEditStandUp, standupDetail }) => {
    const theme = useTheme();
    const { values, handleChange, handleBlur, touched, errors, handleSubmit, setFieldValue, isSubmitting } = useFormikContext();
    const [timeZoneList, setTimeZoneList] = React.useState([]);
    const [selectedDays, setSelectedDays] = React.useState([]);
    const [date, setDate] = React.useState(
        isEditStandUp
            ? moment(standupDetail?.scheduleTime).format('YYYY-MM-DD')
            : values?.scheduleDate
            ? values.scheduleDate
            : moment(values?.scheduleTime).format('YYYY-MM-DD')
    );
    const [selectedTime, setSelectedTime] = React.useState('');
    const [scheduleType, setScheduleType] = React.useState(values?.schedular_type ? values?.schedular_type : 'weekly');
    const { state } = useLocation();
    useEffect(() => {
        setTimeZoneList(getTimeZoneList);
    }, []);

    useEffect(() => {
        let timeZone = values.timeZone ? values.timeZone : getUserTimeZone();
        let type = values?.days?.week === 0 ? 'date' : 'selectedDays';
        const scheduleData = getScheduleDays(type, scheduleType, date, selectedTime, setDate, selectedDays);

        // let schedule = isEditStandUp ? standupDetail.scheduleDays : selectedDays[0];
        if (scheduleType === 'onetime') {
            setFieldValue('scheduleTime', scheduleData[scheduleType]);
        } else {
            setFieldValue('scheduleDays', scheduleData[scheduleType][0]);
        }

        setFieldValue('timeZone', timeZone);
        setFieldValue('schedular_type', scheduleType);
        setFieldValue('time', selectedTime);
        setFieldValue('scheduleDate', date);
    }, [selectedDays, selectedTime, setFieldValue, values.timeZone, date, values.days.week, scheduleType]);

    return (
        <MainCard border={false}>
            <Box
                sx={{
                    width: '100%',
                    [theme.breakpoints.down('lg')]: {
                        width: `100%`
                    }
                }}
            >
                <Box display="flex" flexDirection="row" alignItems="center">
                    <InputLabel sx={{ marginRight: 0.5 }}>Period & Days</InputLabel>
                    <Tooltip title="Choose Days, Period and Time of Reports" placement="top">
                        <div>
                            <IconHelp stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                        </div>
                    </Tooltip>
                </Box>
                <Box sx={{ width: '100%', my: 2, display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ width: '30%' }}>
                        <Select
                            sx={{ width: '80%' }}
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={values.days}
                            name="days"
                            onChange={handleChange}
                        >
                            {daysList.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    value={item}
                                    id={item.id}
                                    onClick={() => {
                                        setScheduleType(item?.key);
                                    }}
                                >
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box sx={{ width: '60%', marginTop: '-15px' }}>
                        {values.days.week === 0 ? (
                            <DatePicker date={date} setDate={setDate} />
                        ) : (
                            <DayPicker
                                day={values.days.week}
                                isEditStandUp={isEditStandUp}
                                standupDetail={standupDetail}
                                setSelectedDays={setSelectedDays}
                            />
                        )}
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row" marginTop={3}>
                    <Box>
                        <InputLabel sx={{ marginRight: 0.5, marginBottom: 2 }}>Time</InputLabel>
                        <Box sx={{ width: '100px' }}>
                            <TimeInputField isEditStandUp={isEditStandUp} standupDetail={standupDetail} setSelectedTime={setSelectedTime} />
                        </Box>
                    </Box>
                    <Box sx={{ marginLeft: 3 }}>
                        <InputLabel sx={{ marginRight: 0.5, marginBottom: 2 }}>Timezone</InputLabel>
                        <Select
                            id="demo-multiple-timezone"
                            value={values.timeZone}
                            name="timeZone"
                            displayEmpty
                            sx={{ width: '300px' }}
                            onChange={handleChange}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                },
                                PaperProps: {
                                    style: {
                                        maxHeight: 300
                                    }
                                }
                            }}
                        >
                            <MenuItem value={'Users Local Timezone'} onClick={() => setFieldValue('isLocalTimeZone', true)}>
                                {"User's Local Timezone"}
                            </MenuItem>
                            {timeZoneList.map((item, index) => (
                                <MenuItem key={index} value={item.value} onClick={() => setFieldValue('isLocalTimeZone', false)}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>
                {/* <Box display="flex" justifyContent="center" alignItems="flex-end" width={'100%'}>
                            <Button
                                variant="contained"
                                disableElevation
                                size="large"
                                type="submit"
                                sx={{ margin: '10px 0px ' }}
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                        </Box> */}
            </Box>
        </MainCard>
    );
};

export default Schedule;
