import { InputAdornment, OutlinedInput, Stack, Box, IconButton, FormHelperText } from '@mui/material';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import QuestionPopupDetails from '../popupComponents/QuestionPopupDetails';
import SelectedTypePopover from '../questionsTypeDialog/SelectedTypePopover';
import DehazeIcon from '@mui/icons-material/Dehaze';
import useStyle from '../../useStyle';
import useQuestions from '../../hooks/useQuestions';
import { lastReportedAtHandler } from 'utils/share';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const DragDropContainer = ({ list, setList }) => {
    // const { onDeleteQuestionHandler, onColorChangehandler, onQuestionTypeChangehandler, getChangedPos } = useQuestions();
    const {
        outlinedInputSXProps,
        questionColorSXProps,
        questionDragIconSXProps,
        questionWrapperSXProps,
        formHelperTextSXProps,
        questionInputWrapperSXProps
    } = useStyle();
    const { setFieldValue, errors, touched, handleBlur } = useFormikContext();

    const onDeleteQuestionHandler = (item) => {
        if (list.length > 1) {
            const itemsArray = list.filter((items) => items.id !== item.id);
            setList(itemsArray);
        }
    };
    const onColorChangehandler = (color, itemIndex) => {
        setList((prevValue) => {
            let x = JSON.parse(JSON.stringify(prevValue));
            x[itemIndex].color = color;
            return [...x];
        });
    };
    const onQuestionTypeChangehandler = (questionType, itemIndex, responseList) => {
        setList((prevValue) => {
            prevValue[itemIndex].questionType = questionType;
            questionType === 'pre-define' && (prevValue[itemIndex].responseList = responseList);
            return [...prevValue];
        });
    };
    const getChangedPos = (result) => {
        const newArray = Array.from(list);
        const [removed] = newArray.splice(result.source.index, 1);
        newArray.splice(result.destination.index, 0, removed);
        setList([...newArray]);
    };
    useEffect(() => {
        setFieldValue('questionsList', list);
    }, [list]);
    const getDraggableField = (item, index, isError) => {
        return (
            <OutlinedInput
                fullWidth
                type={item?.type}
                id={`${item?.id}`}
                name={item?.name}
                sx={outlinedInputSXProps}
                defaultValue={item?.title}
                inputProps={{ maxLength: 201 }}
                onBlur={handleBlur}
                error={isError}
                startAdornment={<Stack sx={{ ...questionColorSXProps, background: `${item.color}` }}></Stack>}
                endAdornment={
                    <InputAdornment position="end">
                        {item?.questionType === 'numeric' || item?.questionType === 'pre-define' ? (
                            <SelectedTypePopover list={list} item={item} index={index} onQuestionTypeChange={onQuestionTypeChangehandler} />
                        ) : item?.questionType === 'plain-text' ? null : null}
                        <QuestionPopupDetails
                            list={list}
                            item={item}
                            index={index}
                            onColorChange={onColorChangehandler}
                            onQuestionTypeChange={onQuestionTypeChangehandler}
                            onDeleteQuestionHandler={() => onDeleteQuestionHandler(item)}
                        />
                    </InputAdornment>
                }
                onChange={(e) =>
                    setList((prevValue) => {
                        let x = JSON.parse(JSON.stringify(prevValue));
                        x[index].title = e.target.value;
                        return [...x];
                    })
                }
                placeholder={!item?.title ? item?.placeholder : item?.title}
            />
        );
    };

    return (
        <DragDropContext onDragEnd={getChangedPos}>
            <Droppable droppableId="list">
                {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps} sx={questionWrapperSXProps}>
                        {list?.map((item, index) => {
                            let isError = touched[item?.id] && Boolean(errors?.questionsList) && errors?.questionsList[index];
                            return (
                                <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
                                    {(provided) => (
                                        <Stack
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            direction="row"
                                            spacing={2}
                                            sx={{
                                                m: '10px 0px',
                                                borderRadius: '8px',
                                                cursor: 'move',
                                                justifyContent: 'right'
                                            }}
                                        >
                                            <Stack
                                                sx={{
                                                    borderRadius: '8px',
                                                    cursor: 'move',
                                                    width: '100%',
                                                    flexDirection: 'column',
                                                    alignItems: 'start'
                                                }}
                                            >
                                                <Stack sx={questionInputWrapperSXProps}>
                                                    <IconButton size="small" sx={questionDragIconSXProps}>
                                                        <DehazeIcon fontSize="inherit" />
                                                    </IconButton>
                                                    {getDraggableField(item, index, isError)}
                                                </Stack>

                                                {touched[item?.id] && errors?.questionsList?.length && (
                                                    <FormHelperText error id="standard-weight-helper-text-name">
                                                        <Stack sx={formHelperTextSXProps}>{errors?.questionsList[index]?.title}</Stack>
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Stack>
                                    )}
                                </Draggable>
                            );
                        })}

                        {provided.placeholder}
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DragDropContainer;
