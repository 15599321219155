import * as React from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { Box, Checkbox, FormControlLabel, Modal, Typography } from '@mui/material';
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 568,
    bgcolor: 'background.paper',
    border: '1px solid transparent',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};
const ConfirmModal = (props) => {
    const theme = useTheme();
    const [confirm, setConfirm] = React.useState(false);
    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => {
                    props.toggle();
                    setConfirm(false);
                }}
            >
                <Box sx={modalStyle}>
                    {props.icon && (
                        <div
                            className="iconHolder"
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '32px' }}
                        >
                            <div
                                className="iconWrapper"
                                style={{
                                    border: '25px solid #f8f8f8',
                                    borderRadius: '50%',
                                    padding: '10px',
                                    width: '130px',
                                    height: '130px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: theme.palette.error.main
                                }}
                            >
                                {props.icon}
                            </div>
                        </div>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography textAlign={'center'} variant="h2" sx={{ fontWeight: 500 }} gutterBottom>
                            {props.title}
                        </Typography>
                        <Typography textAlign={'center'} gutterBottom>
                            {props.content}
                        </Typography>
                        {props.confirmCheckbox ? (
                            <FormControlLabel
                                control={<Checkbox checked={confirm} onChange={() => setConfirm(!confirm)} name="isReport" />}
                                label={props.confirmLabel}
                            />
                        ) : null}
                        <div
                            className="modal-action"
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '32px' }}
                        >
                            <Button
                                disableElevation
                                onClick={props.actionClick}
                                variant={'contained'}
                                size="small"
                                sx={{
                                    marginRight: '32px'
                                }}
                                disabled={!confirm && props.confirmCheckbox}
                                color={props.actionButtonColor || 'primary'}
                            >
                                {props.actionButton}
                            </Button>
                            <Button
                                onClick={() => {
                                    props.toggle();
                                    setConfirm(false);
                                }}
                                variant={'contained'}
                                disableElevation
                                size="small"
                                color={'primary'}
                            >
                                {props.cancelButton}
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default ConfirmModal;
