import { useTheme } from '@mui/material/styles';
const useStyle = () => {
    const theme = useTheme();
    return {
        introMessageSXProps: {
            width: '100%',
            my: 2,

            '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                background: '#ff00001c',
                border: '2px solid red'
            },
            '& .MuiFormHelperText-root': { fontWeight: 500, fontSize: '14px' }
        },
        outroMessageSXProps: {
            width: '100%',
            my: 2,
            '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                background: '#ff00001c',
                border: '2px solid red'
            },
            '& .MuiFormHelperText-root': { fontWeight: 500, fontSize: '14px' }
        },
        outlinedInputSXProps: {
            color: theme.palette.secondary,
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                background: '#ff00001c',
                border: '2px solid red'
            },
            '& .MuiFormHelperText-root': { fontWeight: 500, fontSize: '14px' }
        },
        formHelperTextSXProps: { marginLeft: '30px', fontWeight: 500, fontSize: '14px' },
        questionInputWrapperSXProps: {
            color: theme.palette.secondary,
            borderRadius: '8px',
            cursor: 'move',
            width: '100%',

            flexDirection: 'row',
            alignItems: 'center'
        },
        questionColorSXProps: {
            borderRadius: '50%',
            marginRight: ' 8px',
            minHeight: '8px',
            minWidth: '8px'
        },
        questionWrapperSXProps: {
            height: '100%',
            width: '100%',
            cursor: 'move'
        },
        questionDragIconSXProps: {
            backgroundColor: 'transparent',

            '&:hover': {
                backgroundColor: 'transparent'
            },
            cursor: 'move'
        },
        questionColorPalette: {
            '&:hover': { border: '3px solid white', boxShadow: '0 0 4px 0 rgb(0 0 0 / 16%)', backgroundClip: 'content-box' },
            borderRadius: '100%',
            height: '40px',
            width: '40px',
            margin: '5px',
            display: 'block',
            border: '3px solid transparent'
        }
    };
};

export default useStyle;
