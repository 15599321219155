import newStandup from './newStandup';
import dashboard from './dashboard';
import pages from './pages';
import listStandUps from './standups';
import ListStandUps from 'views/pages/ListStandUps/ListStandUps';
import adminDashboard from './adminDashboard';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, adminDashboard, listStandUps, newStandup]
};

export default menuItems;
