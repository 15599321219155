/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useFormikContext } from 'formik';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getStandUpDetailData } from './standupSlice';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { daysList } from 'utils/common';
import { getAllParticipants } from 'services/participants.services';

const useStandUp = () => {
    const theme = useTheme();
    let { id, path } = useParams();
    const [isEditStandUp, setIsEditStandup] = useState(id ? true : false);
    const [selectedBrodcastChannels, setSelectedBrodcastChannels] = useState([]);
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const dispatch = useDispatch();

    const { standupDetail, loading } = useSelector((state) => state.standups);
    const { channels } = useSelector((state) => state.channels);

    const [tabValue, setTabValue] = React.useState(0);
    const { search } = useLocation();
    const formikRef = useRef();
    useEffect(() => {
        if (isEditStandUp) {
            switch (path) {
                case 'schedule':
                    setTabValue(3);
                    break;
                case 'questions':
                    setTabValue(1);
                    break;
                case 'participants':
                    setTabValue(2);
                    break;
                case 'basic':
                    setTabValue(0);
                    break;
            }
            if (id) {
                dispatch(getStandUpDetailData(id));
            }
        }
    }, [id]);
    useEffect(() => {
        if (isEditStandUp && standupDetail?.name) {
            if (channels.length) {
                let selectedChannels = channels?.filter((channel) => standupDetail.broadcastChannels.includes(channel.id));
                setSelectedBrodcastChannels(selectedChannels);
            }
            getAllParticipants()
                .then((res) => {
                    let user = standupDetail.Participants.map((Participants) => ({
                        slackId: Participants.User.slackUserId,
                        isView: true,
                        isManage: Participants.isManage,
                        isReport: Participants.isReport,
                        id: Participants.id
                    }));

                    let userSlackId = standupDetail.Participants.map((Participants) => Participants.User.slackUserId);

                    let participants = res.members.filter((member) => userSlackId.includes(member.id));
                    let selectedAllParticipants = participants.map((object1) => {
                        let editParticipants = user.filter((object2) => object2.slackId === object1.id);

                        return { ...object1, permissions: { ...editParticipants[0] } };
                    });

                    setSelectedParticipants(selectedAllParticipants);
                })
                .catch((error) => console.log(error));
        }
    }, [standupDetail, isEditStandUp]);

    useEffect(() => {
        if (standupDetail?.name && isEditStandUp) {
            let data = standupDetail;
            const weekIndex = daysList.findIndex((item) => item?.key === data?.schedular_type);
            let formData = {
                name: data.name,
                broadcastChannels: selectedBrodcastChannels,
                questionsList: data.Questions,
                introMessage: data.introMessage,
                outroMessage: data.outroMessage,
                participants: selectedParticipants,
                scheduleDays: data.scheduleDays,
                isSyncToChannel: false,
                days: daysList[weekIndex],
                timeZone: data.timeZone,
                schedular_type: data?.schedular_type,
                time: data.time,
                scheduleTime: data?.scheduleTime,
                isLocalTimeZone: data.isLocalTimeZone
            };
            if (formikRef.current) {
                formikRef.current.setValues(formData);
            }
        }
    }, [standupDetail, isEditStandUp, selectedParticipants, selectedBrodcastChannels]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    function tabProps(index) {
        return {
            id: `create-standup-${index}`,
            'aria-controls': `create-standup-panel-${index}`
        };
    }
    return { handleTabChange, tabProps, tabValue, setTabValue, theme, isEditStandUp, standupDetail, formikRef, loading };
};

export default useStandUp;
