import React, { useEffect } from 'react';
import { Box, CircularProgress, Grid, Stack, useTheme } from '@mui/material';
import InsightsDateRange from './InsightsDateRange/InsightsDateRange';
import InsightsParticipation from './InsightsParticipation/InsightsParticipation';
import InsightsReportStreak from './InsightsReportStreak/InsightsReportStreak';
import { useDispatch, useSelector } from 'react-redux';
import { standUpTimeLine } from '../AddStandUp/standupSlice';
import { resetTimeline } from '../AddStandUp/standupSlice';
import moment from 'moment';
import { format, compareAsc, sub, getISODay, differenceInCalendarDays } from 'date-fns';
import InsightsForOneDay from './InsightsQuestions/InsightsForOneDay/InsightsForOneDay';
import { dateFormat } from 'utils/share';
import InsightsForMultipleDays from './InsightsQuestions/InsightsForMultipleDays/InsightsForMultipleDays';
import {
    multipleDayInsights,
    resetMultipleDaysInsights,
    resetSingleDayInsights,
    singleDayInsights,
    standupMultipleDayInsights,
    standUpPreviousInsights,
    standupSingleDayInsights
} from './insightsSlice';
import { WeekDay } from 'utils/common';
import { differenceInDays } from 'date-fns';
import { standUpsInsights } from 'services/standup.services';
import InsightsParticipationChartForMultipleDays from './InsightsParticipationChartForMultipleDays/InsightsParticipationChartForMultipleDays';
import InsightsMultipleDayChartSkeleton from './InsightsParticipationChartForMultipleDays/InsightsMultipleDayChartSkeleton';

const Insights = ({ standupDetail }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { insights, insightsLoading, multipleDayInsights, singleDayInsights } = useSelector((state) => state.insights);
    const [selectedDateRange, setSelectedDateRange] = React.useState({});
    const [previousDate, setPreviousDate] = React.useState('');
    const [notSchedule, setNotSchedule] = React.useState([]);

    useEffect(() => {
        setNotSchedule(WeekDay.filter((item) => !standupDetail?.scheduleDays?.includes(item.value))?.map((schedule) => schedule.value));
    }, [standupDetail]);
    const checkPreviousDateIsSchedule = (date) => {
        let isWeekOfDay = getISODay(new Date(format(new Date(date), 'yyyy-MM-dd')));

        if (!notSchedule?.includes(isWeekOfDay)) {
            setPreviousDate(date);
            return date;
        } else {
            let checkPrevDate = format(
                new Date(
                    sub(new Date(date), {
                        days: 1
                    })
                ),
                'yyyy-MM-dd'
            );
            setPreviousDate(checkPrevDate);
        }
    };

    useEffect(() => {
        if (standupDetail?.id || selectedDateRange?.startDate || selectedDateRange?.endDate) {
            if (moment(selectedDateRange?.startDate).format('YYYY-MM-DD') === moment(selectedDateRange?.endDate).format('YYYY-MM-DD')) {
                dispatch(resetSingleDayInsights());
                dispatch(resetMultipleDaysInsights());
                dispatch(
                    standupSingleDayInsights({
                        fromDate: moment(selectedDateRange?.startDate).format('YYYY-MM-DD'),
                        toDate: moment(selectedDateRange?.endDate).format('YYYY-MM-DD'),
                        standupId: standupDetail?.id
                    })
                );

                dispatch(
                    standUpPreviousInsights({
                        fromDate: checkPreviousDateIsSchedule(
                            moment(selectedDateRange?.startDate).subtract(1, 'days').format('YYYY-MM-DD')
                        ),
                        toDate: checkPreviousDateIsSchedule(moment(selectedDateRange?.endDate).subtract(1, 'days').format('YYYY-MM-DD')),
                        standupId: standupDetail?.id
                    })
                );
            } else {
                dispatch(resetMultipleDaysInsights());
                dispatch(
                    standupMultipleDayInsights({
                        fromDate: moment(selectedDateRange?.startDate).format('YYYY-MM-DD'),
                        toDate: moment(selectedDateRange?.endDate).format('YYYY-MM-DD'),
                        standupId: standupDetail?.id
                    })
                );
            }
        }
    }, [standupDetail?.id, selectedDateRange]);

    return (
        <Box>
            <Grid container spacing={1} md={12} direction="column" justifyContent="center" alignItems="center">
                <Grid
                    item
                    display="flex"
                    justifyContent="start"
                    alignItems="start"
                    sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}
                >
                    <InsightsDateRange
                        selectedDateRange={selectedDateRange}
                        setSelectedDateRange={setSelectedDateRange}
                        standupDetail={standupDetail}
                    />
                </Grid>
                {moment(selectedDateRange?.startDate).format('YYYY-MM-DD') === moment(selectedDateRange?.endDate).format('YYYY-MM-DD') ? (
                    <Grid container direction="row" spacing={2} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={10} md={6} lg={8}>
                            <InsightsForOneDay standupDetail={standupDetail} />
                        </Grid>

                        <Grid item xs={10} md={6} lg={4}>
                            <Grid container direction="column" justifyContent="center" alignItems="end">
                                <Grid item sx={{ width: '100%', height: '100%', mb: 3 }}>
                                    <InsightsParticipation previousDate={previousDate} standupDetail={standupDetail} />
                                </Grid>
                                <Grid item sx={{ width: '100%', height: '100%' }}>
                                    <InsightsReportStreak
                                        reportType={'reportStreak'}
                                        question={singleDayInsights?.Questions}
                                        streakData={singleDayInsights?.streakData}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container xs={12} sx={{}} direction="column" justifyContent="center" alignItems="center">
                        <Grid
                            item
                            xs={10}
                            display="flex"
                            justifyContent="start"
                            alignItems="start"
                            sx={{
                                display: 'flex',
                                height: '50px',
                                width: '100% ',

                                justifyContent: 'center',
                                alignItems: 'center',
                                mb: 3
                            }}
                        >
                            {insightsLoading ? (
                                <InsightsMultipleDayChartSkeleton />
                            ) : (
                                <InsightsParticipationChartForMultipleDays standupDetail={standupDetail} />
                            )}
                        </Grid>
                        <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
                            <Grid item xs={12} md={!insightsLoading && multipleDayInsights?.Questions?.length === 0 ? 12 : 6}>
                                <InsightsForMultipleDays selectedDateRange={selectedDateRange} standupDetail={standupDetail} />
                            </Grid>

                            <Grid item xs={12} md={!insightsLoading && multipleDayInsights?.Questions?.length === 0 ? 4 : 6}>
                                <Grid container direction="column" justifyContent="center" alignItems="end">
                                    <Grid item sx={{ width: '100%', height: '100%' }}>
                                        <InsightsReportStreak
                                            reportType={'reportChampion'}
                                            streakData={multipleDayInsights?.reportChampion}
                                            question={multipleDayInsights?.Questions}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default Insights;
