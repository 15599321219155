import axios from 'axios';
import { axiosInstance, axiosInstanceSlack } from './api';

export const getAllStandUps = () => {
    let url = `/standups`;

    return new Promise((resolve, reject) => {
        axiosInstance
            .get(url)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
};
export const createNewStandUp = (payload) => {
    let url = `/standups`;

    return new Promise((resolve, reject) => {
        axiosInstance
            .post(url, payload)
            .then((response) => resolve(response.data))
            .catch((err) => reject(err));
    });
};

export const UpdateStandUp = (payload) => {
    let url = `/standups`;

    return new Promise((resolve, reject) => {
        axiosInstance
            .put(url, payload)
            .then((response) => resolve(response.data))
            .catch((err) => reject(err));
    });
};

export const getStandUpDetail = (id) => {
    let url = `/standups/${id}`;
    return new Promise((resolve, reject) => {
        axiosInstance
            .get(url)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
};
export const deleteStandUp = (id) => {
    let url = `/standups/${id}`;

    return new Promise((resolve, reject) => {
        axiosInstance
            .delete(url)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
};
export const UpdateStandUpStatus = ({ id, status }) => {
    let standupStatus = status;
    let url = `/standups/${id}/${standupStatus}`;

    return new Promise((resolve, reject) => {
        axiosInstance
            .patch(url)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
};

export const standUpsInsights = ({ standupId, fromDate, toDate }) => {
    let url = `/insights?`;

    if (standupId) {
        url += `&standupId=${standupId}`;
    }
    if (fromDate && toDate) {
        url += `&fromDate=${fromDate}&toDate=${toDate}`;
    }

    return new Promise((resolve, reject) => {
        axiosInstance
            .get(url)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
};
export const standUpsTimeLine = ({ standupId, fromDate, toDate, page = 1, Participants, Questions, isCSV }) => {
    let url = `/timeline/${isCSV ? 'csv' : ''}?page=${page}`;
    if (standupId) {
        url += `&standupId=${standupId}`;
    }
    if (fromDate && toDate) {
        url += `&fromDate=${fromDate}&toDate=${toDate}`;
    }
    if (Participants?.length) {
        url += `&userIds=${Participants.map((el) => {
            return el;
        })}`;
    }
    if (Questions?.length) {
        url += `&questionIds=${Questions.map((el) => {
            return el;
        })}`;
    }
    return new Promise((resolve, reject) => {
        axiosInstance
            .get(url)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
    });
};
